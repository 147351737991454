import $ from 'jquery';
import {mj_ip} from './environment.js'

export function createChatList(userToken,parentListId,type,title,password,isRequirePd,isPublic) {
	return $.ajax({
		url: mj_ip+"/mjListMes/createChatList?userToken="+userToken
		+"&parentListId="+parentListId
		+"&type="+type
		+"&title="+title
		+"&password="+password
		+"&isRequirePd="+isRequirePd
		+"&isPublic="+isPublic,
		type: "GET"
	})
}

export function sendMjMes(userToken,mes,mesListId,option) {
	return $.ajax({
		url: mj_ip+"/mjListMes/sendMes?userToken="+userToken
		+"&mes="+encodeURIComponent(mes)
		+"&mesListId="+mesListId
		+"&option="+option,
		type: "GET"
	})
}

export function clickMes(userToken,mesId,componentType,customId) {
	return $.ajax({
		url: mj_ip+"/mjListMes/clickMes?userToken="+userToken
		+"&mesId="+mesId
		+"&componentType="+componentType
		+"&customId="+customId,
		type: "GET"
	})
}
export function getSeed(userToken,mesId) {
	return $.ajax({
		url: mj_ip+"/mjListMes/getSeed?userToken="+userToken
		+"&mesId="+mesId,
		type: "GET"
	})
}

export function customZoom(userToken,mesId,prompt) {
	return $.ajax({
		url: mj_ip+"/mjListMes/customZoom?userToken="+userToken
		+"&mesId="+mesId
		+"&prompt="+prompt,
		type: "GET"
	})
}
export function blend(file_list,userToken,mesListId) {
	console.log(file_list)
	var formData = new FormData();
	for (let i = 0; i < file_list.length; i++) {
		formData.append('files', file_list[i]);
	}
	formData.append('userToken', userToken);
	formData.append('mesListId', mesListId);
	console.log(formData)
	return $.ajax({
		url: mj_ip+"/mjListMes/blend",
		type: "POST",
		data: formData,
		processData: false, // 防止 jQuery 自动处理发送的数据
		contentType: false, // 让浏览器自动设置正确的 Content-Type 头
	})
}

export function mjUpload(file_list,userToken,mesListId) {
	console.log(file_list)
	var formData = new FormData();
	for (let i = 0; i < file_list.length; i++) {
		formData.append('files', file_list[i]);
	}
	formData.append('userToken', userToken);
	formData.append('mesListId', mesListId);
	console.log(formData)
	return $.ajax({
		url: mj_ip+"/mjListMes/mjUpload",
		type: "POST",
		data: formData,
		processData: false, // 防止 jQuery 自动处理发送的数据
		contentType: false, // 让浏览器自动设置正确的 Content-Type 头
	})
}
export function getCloudIds() {
	return $.ajax({
		url: mj_ip+"/mjListMes/getCloudIds",
		type: "GET"
	})
}

export function uploadCloud(userToken, mesId, cloudId) {
	return $.ajax({
		url: mj_ip+"/mjListMes/uploadCloud?userToken="+userToken
		+"&mesId="+mesId
		+"&cloudId="+cloudId,
		type: "GET"
	})
}


export function getChatList(userToken,parentListId) {
	return $.ajax({
		url: mj_ip+"/mjListMes/getChatList?userToken="+userToken
		+"&parentListId="+parentListId,
		type: "GET"
	})
}

export function joinPublicChatList(userToken,chatListId,password) {
	return $.ajax({
		url: mj_ip+"/mjListMes/joinPublicChatList?userToken="+userToken
		+"&chatListId="+chatListId
		+"&password="+password,
		type: "GET"
	})
}

export function getChatMesList(userToken,chatListId,prompt,type,pageNum,pageSize) {
	return $.ajax({
		url: mj_ip+"/mjListMes/getChatMesList?userToken="+userToken
		+"&chatListId="+chatListId
		+"&prompt="+prompt
		+"&type="+type
		+"&pageNum="+pageNum
		+"&pageSize="+pageSize,
		type: "GET"
	})
}

export function delChatList(userToken,chatListId) {
	return $.ajax({
		url: mj_ip+"/mjListMes/delChatList?userToken="+userToken
		+"&chatListId="+chatListId,
		type: "GET"
	})
}

export function modifyChatList(userToken,chatListId,title) {
	return $.ajax({
		url: mj_ip+"/mjListMes/modifyChatList?userToken="+userToken
		+"&chatListId="+chatListId
		+"&title="+title,
		type: "GET"
	})
}

export function getChatListMember(userToken,chatListId) {
	return $.ajax({
		url: mj_ip+"/mjListMes/getChatListMember?userToken="+userToken
		+"&chatListId="+chatListId,
		type: "GET"
	})
}

export function transMes(userToken,mes,from,to) {
	return $.ajax({
		url: mj_ip+"/mjListMes/transMes?userToken="+userToken
		+"&mes="+mes
		+"&from="+from
		+"&to="+to,
		type: "GET"
	})
}

export function addCollectList(userToken,title) {
	return $.ajax({
		url: mj_ip+"/collectMjMesList/addCollectList?userToken="+userToken
		+"&title="+title,
		type: "GET"
	})
}

export function removeCollectList(id) {
	return $.ajax({
		url: mj_ip+"/collectMjMesList/removeCollectList?id="+id,
		type: "GET"
	})
}

export function updateCollectList(id, title) {
	return $.ajax({
		url: mj_ip+"/collectMjMesList/updateCollectList?id="+id
		+"&title="+title,
		type: "GET"
	})
}

export function getCollectList(userToken) {
	return $.ajax({
		url: mj_ip+"/collectMjMesList/getCollectList?userToken="+userToken,
		type: "GET"
	})
}


export function getMesInCollectList(userToken,mjMesId) {
	return $.ajax({
		url: mj_ip+"/collectMjMesList/getMesInCollectList?userToken="+userToken
		+"&mjMesId="+mjMesId,
		type: "GET"
	})
}

export function addCollectMes(collectMjMesListId,mjMesId,notes) {
	return $.ajax({
		url: mj_ip+"/collectMjMes/addCollectMes?collectMjMesListId="+collectMjMesListId
		+"&mjMesId="+mjMesId+"&notes="+notes,
		type: "GET"
	})
}

export function removeCollectMes(id) {
	return $.ajax({
		url: mj_ip+"/collectMjMes/removeCollectMes?id="+id,
		type: "GET"
	})
}

export function updateCollectMes(id) {
	return $.ajax({
		url: mj_ip+"/collectMjMes/updateCollectMes?id="+id
		+"&notes="+notes,
		type: "GET"
	})
}

export function getCollectMesList(userToken, notes, collectMjMesListId, pageNum, pageSize) {
	return $.ajax({
		url: mj_ip+"/collectMjMes/getCollectMesList?userToken="+userToken
		+"&notes="+notes
		+"&collectMjMesListId="+collectMjMesListId
		+"&pageNum="+pageNum
		+"&pageSize="+pageSize,
		type: "GET"
	})
}
export function compareCollectList(mjMesId, newNotes, oldCollectMjMesList, newCollectMjMesList) {
	if (newNotes==null){
		newNotes=""
	}
	return  $.ajax({
		url: mj_ip + "/collectMjMes/compareCollectList?mjMesId=" + mjMesId + "&newNotes=" + newNotes,
		type: "POST",
		contentType: "application/json", // 指定发送 JSON 格式数据
		data:JSON.stringify({ 
			oldCollectMjMesList: oldCollectMjMesList, 
			newCollectMjMesList: newCollectMjMesList 
		})
	})
}
