<template>
	<div class="gpt_cont" :style="{color: appear_style==0?'#000':'aliceblue',backgroundColor: appear_style==0?'#fff':'#282828'}">
		<div class="gpt_cont_lef" :style="{backgroundColor: appear_style==0?'':''}">
			<el-button class="new_chat" :disabled="!is_complete" @click="newChat">
				New Chat<i class="el-icon-edit-outline" style="font-weight: 900;float: right;margin-left: 5px;"></i>
			</el-button>
			<div class="mes_list" v-if="mes_list.length>0">
				<div class="mes_item" 
					@mouseover="onListMouseOver(index)" 
					@mouseout="onListMouseOut"
					:disabled="!is_complete" 
					v-for="(item, index) in mes_list" 
					:key="index" 
					@click="clickChat(mes_list.length-index-1)" 
					:style="{color: appear_style==0?'#000':'#fff',boxShadow: mes_list.length-index-1==cur_mes? '1px 1px aqua,-0.2px -0.2px aqua,0.1px 0.1px aqua inset': appear_style == 0?'0.5px 0.5px #535353,0.5px 0.5px 0.5px 0.5px #bdbdbd inset': '',pointerEvents:!is_complete?'none':'auto'}">
					
					<input class="mes_item_con" maxlength="30" :id="'chat_input'+(mes_list.length-index-1)" :readonly="!(mes_chat_edit_index==index)" v-model="mes_list[mes_list.length-index-1].title" :style="{borderColor:mes_chat_edit_index==index?'rgb(118, 118, 118)':'transparent' ,color:appear_style==0? (mes_chat_edit_index==index?'aqua': '#000'):(mes_chat_edit_index==index?'aqua': '#fff'),background:mes_chat_edit_index==index?'#000':'transparent'}"/>
					<div class="mes_item_edit"  v-show="index==on_list_mouseover_index"><i @click="editMesListTitle(mes_list.length-index-1)" class="el-icon-edit-outline" style="font-weight: 900;"></i></div>
					<div class="mes_item_del"  v-show="index==on_list_mouseover_index"><i @click="remove_mes_list" class="el-icon-delete" style="font-weight: 900;" :style="{color:confirm_delete?'red':''}"></i></div>
				</div>
			</div>
		</div>
		
		<div class="cont_rig">
			<div class="gpt_fold_list" @click="fold_list"><i class="el-icon-s-fold" v-show="!re_is_fold_list"></i><i class="el-icon-s-unfold"  v-show="re_is_fold_list"></i></div>
			<div class="cur_model">
				<div class="chat_model">
					<el-button class="sel_model" :style="{color: appear_style==0?'#282828':'aliceblue',backgroundColor: appear_style==0?'#fff':'#282828'}" @click="clickCurModel" type="primary" style="margin-left: 30px;">
						{{current_model.gid}} <i style="font-weight: bold;" class="el-icon-arrow-down"></i>
					</el-button>
				</div>
			</div>
			<div class="mes_cont_input">
				<div class="mes_cont_parent">
					<div class="gpt_mes_cont" v-if="mes_list.length>0">
						<div class="user_item markdown-body" v-for="(item, index) in mes_list[cur_mes].mes" :key="index">
							<div class="user_img"><img class="user_avatar" :src="(item.role==0)?require('../assets/user_logo.jpg'):require('../assets/assistant.png')"/></div>
							<div class="user_name">{{item.role==0?"user":"assistant"}}</div>
								<div class="user_cont" :style="{backgroundColor: appear_style==0 ? '':'#202020', marginLeft: re_wide_mode==1? '10px':'50px'}" style="display:inline-flex;flex-wrap: wrap;" v-if="item.role==0">
									<div :style="{maxWidth: item2.type=='image'?'30%':'',minWidth:item2.type=='image'?'30%':'100%'}" v-for="(item2, index) in item.content" :key="index">
										<img style="margin-top: 5px;" v-if="item2.type=='image'" :src="item2.content"/>
										<p v-if="item2.type=='text'" style="white-space: pre-wrap;">{{item2.content}}</p>
										<div v-if="item2.type=='others'" style="display: flex;margin-top: 15px;">
											<div style="border: 1px solid #9a9a9a;padding: 10px;padding-right: 40px;border-radius: 5px;max-width: 150px;white-space: nowrap;overflow-x: hidden;text-overflow: ellipsis;"><i class="el-icon-tickets" style="background-color: #ff5500;padding: 8px;color: azure;font-size: 18px;border-radius: 5px;margin-right: 10px;"></i>{{item2.content}}</div>
										</div>
									</div>
								</div>
							<div class="user_cont" :style="{backgroundColor: appear_style==0 ? '':'#202020',marginLeft: re_wide_mode==1? '10px':'50px'}" v-if="item.role==1">
								<load1 style="height: 50px;" :spanBackground='appear_style==0?"#aaaaff":"aqua"' v-show="!is_complete&&item.content.length==0&&index==(mes_list[cur_mes].mes.length-1)"></load1>
								<!-- <load1 :spanBackground='appear_style==0?"#aaaaff":"aqua"'></load1> -->
								<vue-markdown :source="item.content" :key="item.content.length" v-highlight v-copy>
								</vue-markdown>
							</div>
						</div>
						<div style="height: 200px;"></div>
					</div>
				</div>
				<div class="mes_input" :style="{marginLeft: re_wide_mode==1? '10px':'-30px'}">
					<div class="temple_file" :style="{color: appear_style==0?'#000':'#fff'}">
						<div style="display:inline-flex;flex-wrap: wrap;" >
							<div @mouseover="onListTempFileMouseOver(index)"
							@mouseout="onListTempFileMouseOut"
							:style="{maxWidth: item.type=='image'?'10%':'',minWidth:item.type=='image'?'10%':'10%'}" 
							style="margin-left: 10px;position: relative;" 
							v-for="(item, index) in temple_file" 
							:key="index">
								<img style="border-radius: 5px;" v-if="item.type=='image'" :src="item.content"/>
								<div v-if="item.type=='others'" style="display: flex;margin-top: 5px;">
									<div style="border: 1px solid #9a9a9a;padding: 1px;padding-left: 6px;padding-right: 6px;border-radius: 3px;"><i class="el-icon-tickets" style="background-color: #ff5500;padding: 4px;color: azure;font-size: 12px;border-radius: 3px;margin-right: 5px;"></i><p style="font-size: 10px;display: inline-flex;max-width: 60px;white-space: nowrap;overflow-x: hidden;text-overflow: ellipsis;">{{item.content}}</p></div>
								</div>
								<div v-show="index==on_list_tempfileover_index" @click="removeTempFile(index)" style="position: absolute;right: 0px;top: 0px;"><i class="el-icon-error" style="color: #9a9a9a;"></i></div>
							</div>
						</div>
					</div>
					<div class="mes_input_con" :style="{backgroundColor: appear_style==0?'#fff':'#282828'}">
						<textarea class="custom_input" id="gpt_textarea" style="border-radius: 2px;" :style="{color: appear_style==0?'#000':'#fff',background: appear_style==0?' #fff':' rgba(44, 62, 80, 0.6)'}"  @keydown="handleKeyDown" placeholder="开始聊天……"></textarea>
						<div class="input_but">
							<el-button :disabled="!is_complete" v-show="is_complete" class="text_but" @click="send_gpt" type="primary" plain size="mini" icon="el-icon-position" style="padding: 3px;font-size: 16px;"></el-button>
							<el-button :disabled="is_complete" v-show="!is_complete" class="text_but" @click="stopFetch" type="primary" plain size="mini" icon="el-icon-video-pause" style="padding: 3px;font-size: 16px;color: #9a9a9a;"></el-button>
							<el-button :disabled="!is_complete" class="text_but" v-show="(current_model.analysis_file)" type="success" @click="clickUpload" plain size="mini" icon="el-icon-upload" style="padding: 3px;font-size: 16px;margin-left: 10px;"></el-button>
							<input type="file" multiple style="display: none;" @change="changeFile" id="inputFile" ref="inputFile" accept=".doc,.docx,.ppt,.pptx,.pdf,image/*"/>
							<el-button class="img_but" @click="scale(null)" type="info" plain size="mini" icon="el-icon-full-screen" :style="{color: appear_style==0?'#747474':'aliceblue'}" style="padding: 3px;font-size: 16px;margin-left: 10px;"></el-button>
						</div>
					</div>
				</div>
			</div>
		</div>
		<UserLogin v-if="is_require_userLogin" :disabled="!is_complete" :style="{pointerEvents:is_complete?'':'none'}" @change="handleLogStatusChange" @changeStyle="change_style"></UserLogin>
		<div>
			<el-drawer
				:visible.sync="drawer"
				:with-header="false"
				size="40%"
				direction="ltr">
				<div class="model" :style="{backgroundColor: appear_style==0?'#fff': '#1b1e20',color: appear_style==0?'#000':'aliceblue'}">
					<div class="official_model">
						<div class="official_model_head">API模型（官方API模型，稳定性高）</div>
						<div class="official_model_body">
							<div class="model_item" :style="{boxShadow: appear_style==0? '1px 1px 3px #424242': '1px 1px 3px #fff'}" v-for="(item,index) in api_models" :key="index" @click="click_sel_model(item)">
								<div class="model_intro_logo">
									<img class="model_intro_img" :src="item.logo"/>
								</div>
								<div class="model_intro_name">{{item.name}}</div>
								<div class="model_intro_used">
									<div class="model_used_num"><i class="el-icon-trophy-1"></i> {{item.use_cnt}}</div>
								</div>
								<div class="model_intro_info">{{item.info}}</div>
							</div>
						</div>
					</div>
					<div class="official_model">
						<div class="official_model_head">官网模型（更聪明但稳定性低，有时会崩）</div>
						<div class="official_model_body">
							<div class="model_item" :style="{boxShadow: appear_style==0? '1px 1px 3px #424242': '1px 1px 3px #fff'}" v-for="(item,index) in office_models" :key="index" @click="click_sel_model(item)">
								<div class="model_intro_logo">
									<img class="model_intro_img" :src="item.logo"/>
								</div>
								<div class="model_intro_name">{{item.name}}</div>
								<div class="model_intro_used">
									<div class="model_used_num"><i class="el-icon-trophy-1"></i> {{item.use_cnt}}</div>
								</div>
								<div class="model_intro_info">{{item.info}}</div>
							</div>
						</div>
					</div>
					<div class="gpts_model">
						<div class="gpts_model_head">
							<div class="gpts_model_head_1">GPT Store</div>
							<div class="gpts_model_head_2">
								<div class="gpts_search">
									<el-input placeholder="GPTs名字、介绍" v-model="gpts_search_input" @change="gptsSearch" class="input-with-select">
										<el-button @click="gptsSearch" style="background-color: antiquewhite;" slot="append" icon="el-icon-search" ></el-button>
									</el-input>
								</div>
								<div class="gpts_prompt">
									<div class="gpts_prompt_item" v-for="(item,index) in gpts_prompt" @click="gptsSearch2(item)" :key="index">{{item}}</div>
								</div>
							</div>
						</div>
						<div class="gpts_model_body">
							<div class="gpts_model_body_loading" v-show="!gpts_sear_is_comp"><load1 :spanBackground='appear_style==0?"#aaaaff":"aqua"'></load1></div>
							<div class="model_item" :style="{boxShadow: appear_style==0? '1px 1px 3px #424242': '1px 1px 3px #fff'}" v-for="(item,index) in gpts_models" :key="index" @click="click_sel_model(item)">
								<div class="model_intro_logo">
									<img class="model_intro_img" :src="item.logo"/>
								</div>
								<div class="model_intro_name">{{item.name}}</div>
								<div class="model_intro_used">
									<div class="model_used_num"><i class="el-icon-trophy-1"></i> {{item.use_cnt}}</div>
								</div>
								<div class="model_intro_info">{{item.info}}</div>
							</div>
						</div>
					</div>
				</div>
			</el-drawer>
		</div>
	</div>
</template>

<script>
import Clipboard from "clipboard";
import $ from 'jquery';
import {is_login_suc, create_uuid, getChatList, getCurChatMes, newMesList, delMesList, savMesListTitle, gptsSearch, getModuleModel} from "@/api/gpt_api";
import UserLogin from "@/components/UserLogin.vue";
import load1 from "@/components/icon/load1.vue";
import {getNotice} from "@/api/common_api";

export default {
components: {
  UserLogin,
  load1
},
props: {
	is_fold_list: Boolean,
	wide_mode: Number,
	is_require_userLogin: Boolean,
},
data() {
	return {
		re_wide_mode:this.wide_mode,
		re_is_fold_list:this.is_fold_list,
		mes_list:[
			{
				chat_list_id: create_uuid(),
				title:null,
				gpt_id:null,
				conversion_id:null, 
				mes:[
					// { role: 0, content: [
					// 	{type:'image',content:'http://localhost:9091/url/images/your_desired_filename.webp'},
					// 	{type:'image',content:'http://localhost:9091/url/images/your_desired_filename.webp'},
					// 	{type:'image',content:'http://localhost:9091/url/images/your_desired_filename.webp'},
					// 	{type:'image',content:'http://localhost:9091/url/images/your_desired_filename.webp'},
					// 	{type:'others',content:'test.doc'},
					// 	{type:'others',content:'test.doc'},
					// 	{type:'text',content:'分析分析'}
					// 	] },
					// { role: 1, content: "![image1](http://localhost:9091/url/images/your_desired_filename.webp)\n ```javascript\nconsole.log('Hello, World!');console.log('Hello, World!');console.log('Hello, World!');\n```" },
					// { role: 0, content: "有什么我能帮助你的吗<img class='model_intro_img' :src='require('../assets/assistant.png')'/> \n早上好啊# 你好 \n ### 有什么我能帮助你的吗 \n ```javascript\nconsole.log('Hello, World!');\n```" },
					// { role: 1, content: "有什么我能帮助你的吗 \n早上好啊# 你好 \n ### 有什么我能帮助你的吗 \n ```javascript\nconsole.log('Hello, World!');\n```" },
				],
				gizmo_id:null,
				model:"gpt-3.5"
			}
		],
		cur_mes:0,
		is_complete: true,
		gpts_sear_is_comp:true,
		gpts_models:[],
		api_models:[],
		default_model: {
			gid: 'gpt-4o-mini',
			gizmo_id: '',
			name: 'gpt-4o-mini',
			logo: require('../assets/assistant.png'),
			use_cnt: "100W+",
			info: "官方API，当其他模型不能用时可以尝试该模型！",
			analysis_file: false,
			type: 'CHATGPT_API'
		},
		office_models: [],
		drawer: false,
		gpts_search_input: "",
		current_model:{
			gid: 'gpt-4o-mini',
			gizmo_id: '',
			name: 'gpt-4o-mini',
			logo: require('../assets/assistant.png'),
			use_cnt: "100W+",
			info: "官网版",
			analysis_file: false
		},
		gpts_prompt:["midjourney","写作","绘画","剧本","导演","代码"], 
		login_suc: false,
		login_qr_code:"",
		interval_is_login:"",	// 轮巡器
		interval_login_counter:0,  // 计数器，用于记录轮巡次数
		on_list_mouseover_index:-1,
		on_list_tempfileover_index:-1,
		mes_chat_edit_index:-1,
		last_scroll_bottom_pos:-1,	// 记录什么时候该滑动，当这次位置上次位置不相等的时候滑动
		appear_style:0,		// 外观样式
		file_list:[],
		temple_file:[],		// {type:"others/image",content:""}
		controller:{},
		confirm_delete: false,
		clipboard: null, // 将 clipboard 实例存储为 data 里的变量
	}
},
created() {
	// 在 created 钩子函数中定义全局变量 clipboard
	this.clipboard = new Clipboard(".copy-btn");
	this.clipboard.on("success", e => {
		this.$message({
			message: "复制成功",
			type: 'success'
		});
		e.clearSelection();
	});
	this.clipboard.on("error", e => {
		this.$message({
			message: "复制失败",
			type: 'error'
		});
	});
},
beforeDestroy(){
	this.stopFetch()
	//停止复制按钮监听
	if (this.clipboard) {
		this.clipboard.destroy();
		this.clipboard = null;
	}
},
directives: {
	copy:{
		inserted(el) {
		  // 找到所有 <pre> 元素
		  let blocks = el.querySelectorAll("pre");
		
		  // 如果找到了 <pre>，才进行处理
		  if (blocks.length > 0) {
		    
		    blocks.forEach(block => {
		      let code = block.querySelector("code");
		      
		      // 确保 code 元素存在
		      if (code) {
		        // 创建复制按钮的容器
		        let div = document.createElement("div");
		        let span = document.createElement("span");
		        div.appendChild(span);
		        div.classList.add("btn-copy");
		
		        // 创建复制按钮
		        let button = document.createElement("button");
		        button.setAttribute("data-clipboard-text", code.innerText);
		        button.innerText = "复制";
		        div.appendChild(button);
		        button.classList.add("copy-btn");
		
		        // 将按钮插入到 <pre> 元素之前
		        block.parentNode.insertBefore(div, block);
		      }
		    });
		  }
		}
	}
},

mounted() {
	this.get_appear_style()
	this.input_change()
	this.verify_is_logining()
	this.gptsSearch2(this.gpts_prompt[0])
	this.fold_list_init()
	this.get_models()
},


methods:{
	clickCurModel(){
		this.drawer=true
		this.get_models()
	},
	getModelByName(model_name, gizmo_id){
		for(let model of this.api_models){
			if (model.name==model_name){
				this.current_model = JSON.parse(JSON.stringify(model))
				return
			}
		}
		for(let model of this.office_models){
			if (model.name==model_name){
				this.current_model = JSON.parse(JSON.stringify(model))
				return
			}
		}
		if (gizmo_id!='' && this.mes_list[this.cur_mes].model=='gpt-4-gizmo'){
			this.current_model.gid = this.mes_list[this.cur_mes].model
			this.current_model.name = this.mes_list[this.cur_mes].model
			this.current_model.gizmo_id = this.mes_list[this.cur_mes].gizmo_id
			this.current_model.type = 'CHATGPT_OFFICE'
		}
	},
	get_models(){
		getModuleModel("CHATGPT_OFFICE").then(response => {
			if(response.status==200){
				var modelList = response.res
				for(let item of modelList){
					item.gid = item.name
					item.gizmo_id = ''
					item.logo = require('../assets/assistant.png')
				}
				this.office_models = modelList
			}
		})
		getModuleModel("CHATGPT_API").then(response => {
			if(response.status==200){
				var modelList = response.res
				for(let item of modelList){
					item.gid = item.name
					item.gizmo_id = ''
					item.logo = require('../assets/assistant.png')
				}
				this.api_models = modelList
			}
		})
	},
	fold_list_init(){
		var left_cont = $('.gpt_cont');
		if(this.re_is_fold_list){
			left_cont[0].style.gridTemplateColumns = '0px auto';
		}else{
			left_cont[0].style.gridTemplateColumns = '250px auto';
		}
	},
	fold_list(){
		this.re_is_fold_list = !this.re_is_fold_list
		var left_cont = $('.gpt_cont');
		if(this.re_is_fold_list){
			left_cont[0].style.gridTemplateColumns = '0px auto';
		}else{
			left_cont[0].style.gridTemplateColumns = '250px auto';
		}
	},
	// changeModelByName(){
	// 	// 前端判断是否能上传文件
	// 	if(this.current_model.gid=="gpt-4"||this.current_model.gid=="gpt-4o"||this.current_model.gid=="gpt-4-gizmo"){
	// 		this.current_model.analysis_file=true
	// 	}else{
	// 		this.current_model.analysis_file=false
	// 	}
	// 	for (let i = 0; i < this.office_models.length; i++) {
	// 		if (this.office_models[i].gid == this.current_model.gid) {
	// 			this.current_model = JSON.parse(JSON.stringify(this.office_models[i]))
	// 			break;
	// 		}
	// 	}
	// },
	removeTempFile(index){
		this.file_list.splice(index, 1)
		this.temple_file.splice(index, 1)
		this.on_list_tempfileover_index=-1
		console.log(this.file_list)
	},
	async changeFile(event) {
		var that=this
		if(event.target.files.length+that.file_list.length>5){
			this.$message({
				message: '最多上传5个文件！',
				type: 'warning'
			});
			return
		}else{
			for (let file of event.target.files) {
				const fileSizeInMB = file.size / (1024 * 1024);
				if (fileSizeInMB > 10) {
					this.$message({
						message: '文件大小超过限制!（最大10M）',
						type: 'warning'
					});
				} else {
					that.file_list.push(file)
					
					let result = await new Promise((resolve, reject) => {
						let reader = new FileReader();
						reader.onload = e => {
							if (file.type.substring(0, 5) == 'image') {
								resolve({ type: "image", content: e.target.result });
							} else {
								resolve({ type: "others", content: file.name });
							}
						};
						reader.onerror = reject;
						reader.readAsDataURL(file);
					});
						
					that.temple_file.push(result);
				}
			}
		}
		document.getElementById('inputFile').value = ''; // 清空文件输入框的值
	},

	clickUpload(){
		// $('#inputFile').click()
		this.$refs.inputFile.click()
	},

	gptsSearch(){
		var that = this
		if(that.gpts_search_input!=''){
			that.gpts_sear_is_comp=false
			gptsSearch(that.gpts_search_input).then(response => {
				if(response.status==200){
					var item_list = JSON.parse(response.res)
					var temp=[]
					for (let item of item_list) {
						temp.push({
							gid: "gpt-4-gizmo",
							gizmo_id: item.id,
							name: item.name,
							logo: require('../assets/assistant.png'),
							use_cnt: item.num_conversations_str,
							info: item.description,
							type: "CHATGPT_OFFICE",
							analysis_file:true
						})
					}
					that.gpts_models=temp
				}
				that.gpts_sear_is_comp=true
			})
		}
	},
	// 点击的方式获得搜索结果
	gptsSearch2(text){
		var that = this
		that.gpts_sear_is_comp=false
		gptsSearch(text).then(response => {
			if(response.status==200){
				var item_list = JSON.parse(response.res)
				var temp=[]
				for (let item of item_list) {
					temp.push({
						gid: "gpt-4-gizmo",
						gizmo_id: item.id,
						name: item.name,
						logo: require('../assets/assistant.png'),
						use_cnt: item.num_conversations_str,
						info: item.description,
						type: "CHATGPT_OFFICE",
						analysis_file:true
					})
				}
				that.gpts_models=temp
			}
			that.gpts_sear_is_comp=true
		})
	},
	get_appear_style(){
		if(localStorage.getItem("appear_style")==null){
			this.appear_style=0
		}else{
			this.appear_style=localStorage.getItem("appear_style")
		}
	},
	change_style(value){
		this.appear_style=value
	},
	handleLogStatusChange(){
		console.log("触发")
		this.verify_is_logining()
	},
	onListMouseOver(index){
		this.on_list_mouseover_index = index
		this.confirm_delete=false
		console.log(index)
	},
	onListMouseOut(){
		this.on_list_mouseover_index = -1
	},
	onListTempFileMouseOver(index){
		this.on_list_tempfileover_index = index
	},
	onListTempFileMouseOut(){
		this.on_list_tempfileover_index = -1
	},
	editMesListTitle(id_suffix){
		var that=this
		
		that.mes_chat_input_read_only = false;
		that.mes_chat_edit_index = that.on_list_mouseover_index;
		var id = "chat_input"+id_suffix
		var inputElement = document.getElementById(id);
		inputElement.focus();
		
		function saveEditTitle(){
			that.logSucAftVerify()
			if(that.login_suc){
				console.log(that.mes_list.length-that.mes_chat_edit_index-1)
				savMesListTitle(
					that.mes_list[that.mes_list.length-that.mes_chat_edit_index-1].chat_list_id, 
					that.mes_list[that.mes_list.length-that.mes_chat_edit_index-1].title).then(response => {
					if(response.status==200){
						console.log("后台编辑成功")
					}
				})
			}
		}
		
		// 定义失去焦点事件处理函数
		function handleBlur() {
			// 在这里可以执行你想要的操作
			saveEditTitle()
			that.mes_chat_edit_index = -1;
			// 移除失去焦点事件监听器和按键事件监听器
			inputElement.removeEventListener('blur', handleBlur);
			inputElement.removeEventListener('keypress', handleKeyPress);
		}
	
		// 定义按键事件处理函数
		function handleKeyPress(event) {
			// 检查是否按下了 Enter 键 (keyCode 13)
			if (event.keyCode === 13) {
				// 在这里可以执行按下 Enter 键后的操作
				saveEditTitle()
				that.mes_chat_edit_index = -1;
				// 移除失去焦点事件监听器和按键事件监听器
				inputElement.removeEventListener('blur', handleBlur);
				inputElement.removeEventListener('keypress', handleKeyPress);
			}
		}
	
		// 添加失去焦点事件监听器和按键事件监听器
		inputElement.addEventListener('blur', handleBlur);
		inputElement.addEventListener('keypress', handleKeyPress);
	},
	remove_mes_list(){
		var that=this
		if (that.confirm_delete){
			that.logSucAftVerify()
			if(that.login_suc){
				if(that.login_suc){
					delMesList(that.mes_list[that.mes_list.length-that.on_list_mouseover_index-1].chat_list_id).then(response => {
						if(response.status==200){
							console.log("后台移除成功")
							if(that.mes_list.length-that.on_list_mouseover_index-1 <= that.cur_mes && that.cur_mes!=0){
								that.cur_mes=that.cur_mes-1
								that.getCurChatMes()
							}
							that.mes_list.splice(that.mes_list.length-that.on_list_mouseover_index-1, 1);
						}		
					})
				}
			}
		}else{
			that.confirm_delete=true
		}
	},
	//不用请求后端的newChat
	surfaceNewChat(){
		var that = this
		if(that.login_suc){
			var chat_list_id = create_uuid();
			that.mes_list.push({
				chat_list_id: chat_list_id,
				title:null,
				gpt_id:null,
				conversion_id:null, 
				mes:[]
			})
			if (that.current_model.gid == null||that.current_model.gid == ""){
				that.current_model = JSON.parse(JSON.stringify(that.default_model))
			}
			that.current_model.gid=(that.current_model.gid == null?"gpt-4o-mini":that.current_model.gid)
			// that.changeModelByName()
			console.log("---------")
			console.log(that.current_model.gid)
			this.cur_mes = this.mes_list.length-1
		}
	},
	newChat(){
		var that = this
		that.logSucAftVerify()
		if(that.login_suc){
			var chat_list_id = create_uuid();
			that.mes_list.push({
				chat_list_id: chat_list_id,
				title:null,
				gpt_id:null,
				conversion_id:null, 
				mes:[]
			})
			if (that.current_model.gid == null||that.current_model.gid == ""){
				that.current_model = JSON.parse(JSON.stringify(that.default_model))
			}
			this.cur_mes = this.mes_list.length-1
			
			if(that.login_suc){
				newMesList(localStorage.getItem("user_token"), that.mes_list[that.cur_mes].chat_list_id).then(response => {
					if(response.status==200){
						console.log("后台添加成功")
						}
					}
				)
			}
		}
	},
	logSucAftVerify(){
		var that = this
		if(localStorage.getItem("user_token")){
			is_login_suc(localStorage.getItem("user_token")).then(response => {
				if(response.status==200){
					if(response.res){
						console.log("验证成功")
						that.login_suc = true
					}else{
						console.log("验证失败")
						that.login_suc = false
						this.$message({
							message: '登录已过期，请重新登录！',
							type: 'warning'
						});
					}
				}
			})
		}else{
			that.login_suc = false
			this.$message({
				message: '您还未登陆，请扫码登录！',
				type: 'warning'
			});
		}
	},
	getChatList(){
		var that = this
		getChatList(localStorage.getItem("user_token")).then(response => {
			if(response.status==200){
				console.log(response)
				if(response.res.length>0){
					that.mes_list = response.res
					that.cur_mes = that.mes_list.length-1
				}
				console.log("-----------")
				console.log(that.cur_mes)
				that.getCurChatMes()
			}
		})
	},

	getCurChatMes(){
		var that = this
		if(that.login_suc){
			getCurChatMes(that.mes_list[that.cur_mes].chat_list_id).then(response => {
				if(response.status==200){
					that.mes_list[that.cur_mes].mes=[]
					for (let i = 0; i < response.res.length; i++) {
						if(response.res[i].role==0){
							console.log(JSON.parse(response.res[i].content))
							that.mes_list[that.cur_mes].mes.push({role: 0, content: JSON.parse(response.res[i].content)})
						}else{
							that.mes_list[that.cur_mes].mes.push(response.res[i])
						}
					}
					that.getModelByName(that.mes_list[that.cur_mes].model, that.mes_list[that.cur_mes].gizmo_id)
					// that.current_model.gid = that.mes_list[that.cur_mes].model
					// that.current_model.gizmo_id = that.mes_list[that.cur_mes].gizmo_id
					// that.changeModelByName()
					console.log(that.mes_list)
					requestAnimationFrame(() => {
						that.pos_bottom()
					});
				}
			})
		}
	},
	// 针对新增的元素不能使用js变换后的属性而定义的方法，在新增元素后调用此方法
	
	scale(design_mode){
		if (this.re_wide_mode==undefined||this.re_wide_mode==null){
			this.re_wide_mode=2
		}
		if (design_mode!=undefined&&this.re_wide_mode!=null){
			this.re_wide_mode=design_mode
		}
		console.log(this.re_wide_mode)
		if(this.re_wide_mode==1){
			// $(".cont_rig").css("grid-template-columns", "24% 76%");
			$(".gpt_mes_cont").css("max-width", "620px");
			$(".gpt_mes_cont").css("margin-left", "calc((100% - 620px) / 2 - 5%)");
			$(".mes_input").css("max-width", "680px");
			$(".mes_input").css("transform", "translateX(-5%)");
			// $(".user_cont").css("margin-left", "50px");
			this.re_wide_mode=2
		}else if(this.re_wide_mode==2){
			// $(".cont_rig").css("grid-template-columns", "2% 98%");
			$(".gpt_mes_cont").css("max-width", "70%");
			$(".gpt_mes_cont").css("margin-left", "calc((100% - 70%) / 2 - 3%)");
			$(".mes_input").css("max-width", "70%");
			$(".mes_input").css("transform", "translateX(-3%)");
			// $(".user_cont").css("margin-left", "50px");
			this.re_wide_mode=3
		}else{
			// $(".cont_rig").css("grid-template-columns", "2% 98%");
			$(".gpt_mes_cont").css("max-width", "95%");
			$(".gpt_mes_cont").css("margin-left", "calc((100% - 95%) / 2 )");
			$(".mes_input").css("max-width", "95%");
			$(".mes_input").css("transform", "translateX(-1%)");
			// $(".user_cont").css("margin-left", "10px");
			this.re_wide_mode=1
		}
	},
	// is_login_suc(){
	// 	var that=this
	// 	that.interval_login_counter++;
	// 	is_login_suc(localStorage.getItem("user_token")).then(response => {
	// 		if(response.status==200 && response.res!=null){
	// 			that.login_suc=true;
	// 			// localStorage.setItem("user_token", that.user_token);
	// 			that.getChatList()
	// 			clearInterval(that.interval_is_login)
	// 		}
	// 	})
	// 	if(that.interval_login_counter>=60){
	// 		clearInterval(that.interval_is_login)
	// 	}
	// 	console.log(that.interval_login_counter)
	// },
	// create_user_token(){
	// 	this.user_token = create_uuid()
	// 	console.log("去掉破折号后的UUID:", this.user_token);
	// },
	verify_is_logining(){
		var that=this
		if(localStorage.getItem("user_token")!=null){
			is_login_suc(localStorage.getItem("user_token")).then(response => {
				if(response.status==200 && response.res){
					that.login_suc=true;
					that.getChatList()
					console.log('response.res')
					console.log(response.res)
				}else{
					that.login_suc=false;
				}
			})
		}else{
			that.login_suc=false;
			this.cur_mes=-1;
			this.mes_list=[]
		}
	},
	// get_qrCode(){
	// 	var that=this
	// 	if(!that.login_suc){
	// 		that.create_user_token()
	// 		get_qrCode("login",that.user_token).then(response => {
	// 			if(response.status==200){
	// 				that.login_qr_code = response.res
	// 				console.log("qrCode:"+response.res)
	// 			}
	// 		})
	// 	}
	// 	that.interval_login_counter=0
	// 	clearInterval(that.interval_is_login)
	// 	that.interval_is_login=setInterval(that.is_login_suc,1000)
	// },
	// login_out(){
	// 	localStorage.removeItem("user_token");
	// 	this.login_suc=false
	// 	this.get_qrCode()
	// 	this.cur_mes=0;
	// 	this.mes_list=this.exm_mes_list
	// },
	
	click_sel_model(item){
		console.log(item)
		console.log(this.cur_mes)
		try{
			if(JSON.stringify(item)!=JSON.stringify(this.current_model)){
				console.log("触发")
				this.mes_list[this.cur_mes].conversion_id=null
			}
		}catch{}
		this.current_model = JSON.parse(JSON.stringify(item))
		this.drawer=false
	},
	async send_gpt(){
		// this.mes_list[0].mes.push(JSON.parse(JSON.stringify(this.mes_list[0].mes[0])))
		var that=this
		getNotice(this)
		that.logSucAftVerify()
		
		if(that.mes_list.length==0){
			that.surfaceNewChat()
		}
		if(that.file_list.length>0&&!that.current_model.analysis_file){
			this.$message({
				showClose: true,
				message: '当前模型不支持上传文件！',
				type: 'warning'
			});
			return
		}
		if (that.current_model.gid == null||that.current_model.gid == ""){
			that.current_model = JSON.parse(JSON.stringify(that.default_model))
			// that.changeModelByName()
		}
		console.log("------------")
		console.log(that.current_model)
		
		if(that.current_model.type == 'CHATGPT_OFFICE'){
			that.send_office_mes()
		}else{
			that.send_api_mes()
		}
	},
	stopFetch() {
		try{
			this.controller.abort();
			console.log('Fetch request aborted');
		}catch{
			
		}
		this.is_complete=true
	},
	async send_api_mes() {
		var that=this
		if($("#gpt_textarea").val()!=""&&$("#gpt_textarea").val().length<=20000 && that.mes_list.length>0){
			var mes = $("#gpt_textarea").val()
			if(that.mes_list[that.cur_mes].title==null&&that.mes_list[that.cur_mes].mes.length==0){
				that.mes_list[that.cur_mes].title = mes.substring(0,20)
			}
			that.mes_list[that.cur_mes].model = that.current_model.gid
			that.mes_list[that.cur_mes].gizmo_id = that.current_model.gizmo_id
			that.mes_list[that.cur_mes].mes.push({ role: 0, content: [{type:'text',content: $("#gpt_textarea").val()}] });
			that.is_complete=false
			$("#gpt_textarea").val("")
			$("#gpt_textarea")[0].style.height="auto"
			requestAnimationFrame(() => {
				that.scroll_bottom()
			})
			var messages_list = []
			let memory_num = Number(that.current_model.memory_num)
			console.log("memory_num")
			console.log(memory_num)
			memory_num = (memory_num == 0? 5:memory_num)
			var lastFive = that.mes_list[that.cur_mes].mes.slice(-memory_num);
			console.log(lastFive)
			for (let i = 0; i < lastFive.length; i++) {
			    if(lastFive[i].role==0){
					console.log(lastFive[i].content)
					for (let j = 0; j < lastFive[i].content.length; j++) {
						if(lastFive[i].content[j].type=='text'){
							messages_list.push({"role": "user","content": lastFive[i].content[j].content})
						}
					}
				}else{
					messages_list.push({"role": "assistant","content": lastFive[i].content})
				}
			}
			that.mes_list[that.cur_mes].mes.push({ role: 1, content: "" })
			
			try {
				let response =null;
				this.controller = new AbortController();
				try {
					const requestData = {
					    chat_list_id: that.mes_list[that.cur_mes].chat_list_id,
					    title: that.mes_list[that.cur_mes].title,
					    model: that.current_model.gid,
					    messages: messages_list,
					    user_token: localStorage.getItem("user_token")
					};
					const signal = this.controller.signal;
					
					const requestOptions = {
						method: "POST",
						body: JSON.stringify(requestData),
						headers: {
							"Content-Type": "application/json"
						},
						redirect: "follow",
						signal: signal
					};
					response = await fetch(that.config.ip+'/gpt/sendApiMes', requestOptions);
					if (!response.ok) {
						response = await fetch(that.config.ip+'/gpt/sendApiMes', requestOptions);
						if (!response.ok) {
							that.process_error('Network response was not ok')
							throw new Error('Network response was not ok');
						}
					}
					// 处理服务器响应的逻辑
				} catch (error) {
					// 处理连接错误的逻辑
					that.process_error(error.toString())
					return
				}
				
				const reader = response.body.getReader();
				const textDecoder = new TextDecoder();
				var result = true;
				var i=0;
				var is_first_msg = true;	//后端发过来的第一次消息直接滑动到内容底部
				
				while (result) {
					const { done, value } = await reader.read();
					if (done) {
						if(i==0){
							that.process_error("未知错误！请重试")
						}
						result = false;
						break;
					}
					i+=1;
					const chunkText = textDecoder.decode(value);
					console.log("--------")
					console.log(chunkText)
					that.mes_list[that.cur_mes].mes[that.mes_list[that.cur_mes].mes.length-1].content+=chunkText
					
					if(is_first_msg){
						setTimeout(()=>{
							requestAnimationFrame(() => {
								that.scroll_bottom()
							});
						},200)
						is_first_msg=false
					}
						
					requestAnimationFrame(() => {
						that.judge_is_bottom()
					});
				}
				that.is_complete=true
				requestAnimationFrame(() => {
					that.judge_is_bottom()
				});
			} catch (e) {
				if (e.name === 'AbortError') {
					console.log('Fetch aborted');
				} else {
					console.error('Fetch error:', e.toString());
					that.process_error(e.toString())
				}
			}
		}else if($("#gpt_textarea").val().length>20000){
			console.log("111")
			this.$message({
				showClose: true,
				message: '超过最大文字限制',
				type: 'warning'
			});
		}
	},
	async send_office_mes() {
		var that=this
		
		if(($("#gpt_textarea").val()!=""||that.file_list.length>0)&&$("#gpt_textarea").val().length<=20000 &&that.login_suc&&that.mes_list.length>0){
			var mes = $("#gpt_textarea").val()
			if(that.mes_list[that.cur_mes].title==null&&that.mes_list[that.cur_mes].mes.length==0){
				that.mes_list[that.cur_mes].title = mes.substring(0,20)
			}
			that.mes_list[that.cur_mes].model = that.current_model.gid
			that.mes_list[that.cur_mes].gizmo_id = that.current_model.gizmo_id
			that.is_complete=false
			that.temple_file.push({type:'text',content: $("#gpt_textarea").val()})
			that.temple_file.sort((a, b) => {
			    const order = {image: 1, others: 2, text: 3};
			    return order[a.type] - order[b.type];
			});
			that.mes_list[that.cur_mes].mes.push({ role: 0, content: that.temple_file });
			that.mes_list[that.cur_mes].mes.push({ role: 1, content: "" })
			that.temple_file=[]
			$("#gpt_textarea").val("")
			$("#gpt_textarea")[0].style.height="auto"
			requestAnimationFrame(() => {
				that.scroll_bottom()
			})
			console.log("---------------")
			console.log(that.mes_list[that.cur_mes].chat_list_id)
			try {
				let response =null;
				try {
					var data = {
						chat_list_id: that.mes_list[that.cur_mes].chat_list_id,
						title: that.mes_list[that.cur_mes].title,
						model: that.current_model.gid,
						conversation_id: that.mes_list[that.cur_mes].conversion_id,
						mes: mes,
						gizmo_id: that.current_model.gizmo_id,
						gpt_id: that.mes_list[that.cur_mes].gpt_id,
						user_token: localStorage.getItem("user_token")
					}
					// response = await fetch(that.config.ip+'/gpt/sendMes', { method: 'POST', body: JSON.stringify(data) });
					// if (!response.ok) {
					// 	that.process_error('Network response was not ok')
					// 	throw new Error('Network response was not ok');
					// }
					const formdata = new FormData();
					for (let i = 0; i < this.file_list.length; i++) {
						formdata.append('files', this.file_list[i]);
					}
					formdata.append('message', JSON.stringify(data));
					this.file_list=[]
					this.controller = new AbortController();
					const signal = this.controller.signal;
					console.log("---------signal---------")
					console.log(signal)
					const requestOptions = {
						method: "POST",
						body: formdata,
						redirect: "follow",
						signal: signal
					};
					
					response = await fetch(that.config.ip+'/gpt/gptUpload', requestOptions);
					if (!response.ok) {
						response = await fetch(that.config.ip+'/gpt/gptUpload', requestOptions);
						if (!response.ok) {
							that.process_error('Network response was not ok')
							throw new Error('Network response was not ok');
						}
					}
					// 处理服务器响应的逻辑
				} catch (error) {
					// 处理连接错误的逻辑
					// 中断的错误
				
					if (error.name === 'AbortError') {
						console.log('Fetch aborted');
					} else {
						console.error('Fetch error:', error);
						that.process_error(error.toString())
					}
					return
				}
				
				const reader = response.body.getReader();
				const textDecoder = new TextDecoder();
				var result = true;
				var i=0;
				var is_first_msg = true;	//后端发过来的第一次消息直接滑动到内容底部
				
				while (result) {
					const { done, value } = await reader.read();
					if (done) {
						if(i==0){
							that.process_error("未知错误！请重试")
						}
						result = false;
						break;
					}
					i+=1;
					const chunkText = textDecoder.decode(value);
					console.log("--------")
					console.log(chunkText)
					// 使用正则表达式进行匹配
					const match = chunkText.match(/gpt_id:(\d+) conversation_id:([a-f\d-]+)(?: (.+))?/);
					
					var gptId =  match ? match[1] : null;
					var conversationId = match ? match[2] : null;
					var afterSecondSpace = match ? match[3] : null;
					afterSecondSpace = afterSecondSpace?afterSecondSpace:"";
					
					if(conversationId!=null&&gptId!=null){
						that.mes_list[that.cur_mes].conversion_id = conversationId
						that.mes_list[that.cur_mes].gpt_id = gptId
						console.log(conversationId);
						console.log(gptId);
						that.mes_list[that.cur_mes].mes[that.mes_list[that.cur_mes].mes.length-1].content+=afterSecondSpace
					}else{
						that.mes_list[that.cur_mes].mes[that.mes_list[that.cur_mes].mes.length-1].content+=chunkText
						
						if(is_first_msg){
							setTimeout(()=>{
								requestAnimationFrame(() => {
									that.scroll_bottom()
								});
							},200)
							is_first_msg=false
						}
					}
						
					requestAnimationFrame(() => {
						that.judge_is_bottom()
					});
				}
				that.is_complete=true
				requestAnimationFrame(() => {
					that.judge_is_bottom()
				});
			} catch (e) {
				console.log("-------------")
				console.log(e.name)
				if (e.name === 'AbortError') {
					console.log('Fetch aborted');
				} else {
					console.error('Fetch error:', e.toString());
					that.process_error(e.toString())
				}
			}
		}
		else if($("#gpt_textarea").val().length>20000){
			console.log("111")
			this.$message({
				showClose: true,
				message: '超过最大文字限制',
				type: 'warning'
			});
		}
		
	},
	
	
	// get_gpts_model(type,index){
	// 	var that = this
	// 	get_gpts_list(type,index).then(response => {
	// 		if(response.status==200){
	// 			that.gpts_models = JSON.parse(response.res)
	// 			console.log(that.gpts_models)
	// 		}
	// 	})
	// },
	
	handleKeyDown(event) {
		if (event.key === "Enter" && event.shiftKey) {
		// 如果按下 Shift + Enter，则换行
			this.inputText += "\n";
		} else if (event.key === "Enter" && this.is_complete) {
		// 如果只按下 Enter，则执行提交逻辑
			event.preventDefault(); // 阻止默认的 Enter 换行行为
			this.send_gpt();
		}
	},
	clickChat(index){
		var that=this
		that.cur_mes = index
		console.log(that.mes_list[that.cur_mes].model)
		
		if (that.current_model.gid == null||that.current_model.gid == ""){
			that.current_model = JSON.parse(JSON.stringify(that.default_model))
		}
		// that.changeModelByName()
		that.getCurChatMes()
	},
	
	scroll_bottom(){
		// 获取指定元素
		try{
			var cont_rig = document.getElementsByClassName('mes_cont_parent')[0]; // 替换为你的元素的ID
			if(this.last_scroll_bottom_pos!=cont_rig.scrollHeight){
				// 滚动到元素底部
				cont_rig.scrollTop = cont_rig.scrollHeight;
			}
			this.last_scroll_bottom_pos=cont_rig.scrollHeight
		}catch{}
	},
	pos_bottom(){
		// 获取指定元素
		try{
			var cont_rig = document.getElementsByClassName('mes_cont_parent')[0]; // 替换为你的元素的ID
			// 定位到元素底部
			cont_rig.scrollTo({
				top: cont_rig.scrollHeight,
				behavior: 'instant' // 或者使用 smooth
			});
		}catch{}
	},
	
	judge_is_bottom(){
		try{
			var element = document.getElementsByClassName('mes_cont_parent')[0]; // 替换为你的元素的ID
			
			// 获取元素离底部的距离
			var distanceToBottom = element.scrollHeight - element.scrollTop - element.clientHeight;
			
			console.log("元素离底部的距离为：" + distanceToBottom + " 像素");
			if(distanceToBottom <=200){
				this.scroll_bottom()
			}
		}catch{}
	},
	
	process_error(e){
		var that=this
		that.is_complete=true
		that.mes_list[that.cur_mes].mes[that.mes_list[that.cur_mes].mes.length-1].content = e
		requestAnimationFrame(() => {
			that.scroll_bottom()
		});
	},
	input_change(){
		$("#gpt_textarea").on("input", function() {
			this.style.height = "auto";
			this.style.height = (this.scrollHeight-12) + "px";
			this.style.height = (this.scrollHeight) + "px";
		});
	},
}
}
</script>

<style>
	.gpt_cont{
		position: absolute;
		width: 100%;
		height: 100%;
		overflow: hidden;
		display: grid;
		grid-template-rows:100%;
		color: #fff;
		
		.gpt_cont_lef{
			height: 100%;
			margin: 10px 0px 10px 0px;
			margin-left: 10px;
			display: grid;
			grid-template-rows:45px auto 100px;
			box-shadow: 0.4px 0px #7d7d7d;
			overflow-y: hidden;
			overflow-x: hidden;
			position: relative;
			
			.new_chat{
				width: 95%;
				height: 45px;
				background-color: #55557f;
				color: aliceblue;
				font-weight: 600;
				display: flex;
				justify-content: center;
				align-items: center;
				border-radius: 10px;
				margin-left: 3px;
			}
			
			.mes_list{
				position: relative;
				width: 98%;
				margin: 10px 6px 0px 3px;
				overflow-y: scroll;
				
				.mes_item{
					position: relative; 
					width: 95%;
					height: 45px;
					border-radius: 5px;
					margin-top: 8px;
					/* color: aliceblue; */
					display: flex;
					align-items: center;
					padding-right: 5px;
					background-color: transparent;
					
					.mes_item_con{
						width: 100px;
						margin-left: 10px;
						display:inline;
						white-space: nowrap;
						overflow-x: hidden;
						text-overflow: ellipsis;/*溢出文本显示省略号*/
					}
					.mes_item_edit{
						position: absolute;
						right: 35px;
						display:inline;
						width: 50px;
						height: 50px;
						/* background-color: antiquewhite; */
						justify-content: center;
						text-align: center;
						line-height: 50px;
						cursor: pointer;
					}
					.mes_item_del{
						position: absolute;
						right: 5px;
						display:inline;
						width: 50px;
						height: 50px;
						/* background-color: antiquewhite; */
						justify-content: center;
						text-align: center;
						line-height: 50px;
					}
				}
			}
		}
		
		.cont_rig{
			width: 100%;
			height: 100%;
			/* display: flex;
			justify-content: center; */
			overflow-y: hidden;
			scroll-behavior: smooth;
			display: grid;
			position: relative;
			grid-template-columns: 0% 100%;
			grid-template-areas: 'a b';
			
			.gpt_fold_list{
				width: 30px;
				height: 30px;
				font-size: 12px;
				margin-top: 15px;
				text-align: center;
				line-height: 30px;
				border-radius: 0px 3px 10px 0px;
				box-shadow: 1px 1px #959595;
				position: absolute;
				left: 0px;
				z-index: 101;
			}
			
			.cur_model{
				max-width: 300px;
				padding-top: 7px;
				padding-left: 10px;
				display: inline-flex;
				position: fixed;
				z-index: 100;
				
				.chat_model{
					width: 50%;
					padding: 5px;
					
					.sel_model{
						border: 0px;
						border: 0px solid #fff;
						box-shadow: 0.4px 0.2px #000, -0.4px -0.2px #aaaa7f;
						text-overflow: ellipsis;
						overflow: hidden;
						max-width: 150px;
					}
				}
				.draw_model{
					width: 50%;
					padding: 5px;
				}
			}
			
			.mes_cont_input{
				grid-area: b;
				position: relative;
				width: 100%;
				height: 100%;
				overflow: hidden;
				display: flex;
				justify-content: center;
				
				
				.mes_cont_parent{
					width: 100%;
					overflow: scroll;
					height: 100%;
					
					.gpt_mes_cont{
						max-width: 620px;
						margin: 0 auto; /* 水平居中 */
						margin-left: calc((100% - 620px) / 2 - 5%); /* 水平居中偏左 10% */

						.user_item{
							width: 100%;
							margin-top: 20px;
							display: grid;
							grid-template-columns: 50px auto;
							grid-template-rows: 50px auto;
							grid-template-areas: 'a b' 
												 'd d';
					
							.user_img{
								grid-area: a;
								display: flex;
								width: 40px;
								height: 100%;
								align-self: center;
								justify-self: center;
								user-select: none;
								
								.user_avatar{
									width: 40px;
									height: 40px;
									border-radius: 20px;
								}
							}
							.user_name{
								grid-area: b;
								font-weight: 700;
								padding: 10px;
								padding-top: 5px;
								border-radius: 5px;
								align-self: center;
								user-select: none;
							}
							
							.user_cont{
								grid-area: d;
								overflow: auto;
								border-radius: 10px;
								padding: 0 15px;
								margin-left: 50px;
								max-width: calc(100% - 40px); /* 使用 calc() 实现减去固定宽度 */
								width: fit-content; /* 自适应宽度 */
								/* 样式1 */
								border-left: 0.1em solid #737bb4;
								/* 样式2 */
								/* box-shadow: 0.1px 0px #fff inset; */
							}
						}
					}
				}
				
				.mes_input{
					/* background-color: #2c3e50; */
					position: absolute;
					bottom: 0;
					display: flex;
					width: 100%;
					transform: translateX(-5%);
					max-width: 680px;
					flex-wrap: wrap;
					
					.temple_file{
						width: 100%;
						padding: 5px;
						margin-bottom: 10px;
						border-radius: 10px;
						background-color: transparent;
					}
					
					.mes_input_con{
						width: 100%;
						border: 2px solid #ccc;
						padding: 15px 10px 10px 20px;
						border-radius: 15px;
						margin-bottom: 20px;
						/* 样式1 */
						/* background-color: #fff; */
						/* 样式2 */
						/* background-color: #2c3e50; */
						justify-content: space-around;
						display: inline-flex;
						
						.custom_input{
							height: 28px;
							line-height: 1; /* 例如，设置为1.5倍行高 */
							width: 95%;
							max-height: 180px;
							border: 0px;
							font-size: 16px;
							background-color: transparent;
							resize:none;
							font-weight: 600;
							align-self: center;
						}
						
						.input_but{
							display: inline-flex;
							padding: 10px;
							justify-content: center;
							max-height: 30px;
							align-self: end;
						
							
							.text_but{
								background-color: transparent;
							}
							
							.img_but{
								background-color: transparent;
								color: aliceblue;
							}
						}
					}
				}
			}
		}
		.model{
			/* 样式1 */
			/* background-color: aliceblue; */
			/* 样式2 */
			background-color: #1b1e20;
			
			/* color: aliceblue; */
			position: relative;
			padding-bottom: 300px;
			
			.official_model{
				box-shadow: 0px 0.5px 1px #fff;
				height: 750rpx;
				
				.official_model_head{
					height: 40px;
					line-height: 40px;
					font-size: 20px;
					font-weight: 900;
					padding: 20px;
					padding-bottom: 0px;
				}
				
				.official_model_body{
					display: inline-flex;
					flex-wrap: wrap;
					justify-content: space-between;
					padding: 20px;
				}
			}
			
			.gpts_model{
				
				.gpts_model_head{
					height: 40px;
					padding: 10px;
					
					.gpts_model_head_1{
						font-size: 20px;
						font-weight: 900;
					}
					
					.gpts_model_head_2{
						color: aliceblue;
						padding: 10px 20px 20px 20px;
						
						.gpts_search{
							width: 300px;
							display: inline-block;
							float: right;
							margin-left: 6px;
						}
						.gpts_prompt{
							display: inline-block;
							float: left;
							font-size: 14px;
							
							
							.gpts_prompt_item{
								padding: 10px;
								display: inline-block;
								border-radius: 5px;
								text-align: center;
								margin-right: 10px;
								background-color: #1a2f2c;
								color: aquamarine;
								cursor: pointer;
							}
						}
					}
				}
				
				.gpts_model_body{
					display: inline-flex;
					flex-wrap: wrap;
					justify-content: space-between;
					padding: 20px;
					
					.gpts_model_body_loading{
						width: 100%;
						display: flex;
						justify-content: center;
						margin-top: 30px;
						margin-bottom: 30px;
					}
				}
			}
		}
	}
	.model_item{
		min-width: 220px;
		flex: 1;
		margin: 10px;
		box-shadow: 1px 1px 3px #fff;
		border-radius: 10px;
		padding: 15px;
		cursor: pointer;
		display: grid;
		grid-template-columns: 80px auto;
		grid-template-rows: auto auto,auto;
		grid-template-areas: 'a b' 
							'a d'
							'c c';
		
		.model_intro_logo{
			grid-area: a;
			
			.model_intro_img{
				border-radius: 50%;
				width: 70px;
				height: 70px;
			}
		}
		.model_intro_name{
			grid-area: b;
			align-self: center;
			font-size: 19px;
			font-weight: 800;
			white-space: nowrap;
			text-overflow: ellipsis;
			overflow: hidden;
		}
		.model_intro_used{
			grid-area: d;
			align-self: center;
			border-radius: 5px;
			display: flex;
			
			.model_used_num{
				border: 1px solid #fff;
				color: #65ecbe;
				font-size: 13px;
				padding: 5px 8px 5px 8px;
				border-radius: 15px;
			}
		}
		.model_intro_info{
			grid-area: c;
			margin-top: 10px;
		}
		
	}
	
	
	
	.btn-copy{
		display: block;
		background-color: #343541;
		border-radius: 8px 8px 0px 0px;
		padding: 8px;
		height: 20px;
		margin-bottom: -1em;
		
		.copy-btn{
			float: right;
			background-color: transparent;
			color: aliceblue;
			border-radius: 3px;
		}
	}
	.hljs{
		border-radius: 8px 8px 8px 8px;
		padding: 8px;
		font-size: 16px;
	}
	.el-button{
		margin-left: 10px;
		color: #000;
	}
	.el-input__inner{
		background-color:transparent;
		border: 0px solid #fff;
		box-shadow: 0.4px 0.2px #fff, -0.4px -0.2px #000;
		/* color: #fff; */
	}
	
	.el-drawer__body{
		/* background-color: transparent; */
	}
	img{
		max-width: 100%;
	}
	
	/* 设置滚动条的基本样式 */
	::-webkit-scrollbar {
		width: 15px; /* 设置滚动条的宽度 */
		height: 15px;
	}
	/* 设置滑块的样式 */
	::-webkit-scrollbar-thumb {
		background-color: #999; /* 设置滑块的背景色 */
	}
	/* 设置轨道的样式 */
	::-webkit-scrollbar-track {
		background-color: transparent; /* 设置轨道的背景色 */
	}
	/* 设置按钮的样式 */
	::-webkit-scrollbar-button {
		display: none; /* 不显示按钮 */
	}
	/* 设置角落处的样式 */
	::-webkit-scrollbar-corner {
		display: none; /* 不显示角落处 */
	}
	textarea:focus-visible {
		outline:-webkit-focus-ring-color auto 0px;
	}
	input {
	  outline: none; /* 移除默认边框 */
	  cursor: pointer;
	  /* border-color: transparent; */
	  /* border-color: -internal-light-dark(rgb(118, 118, 118), rgb(133, 133, 133)); */
	}
	
</style>