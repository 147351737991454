const LINUX_PLATFORM = "LINUX"
const WINDOWS_PLATFORM = "WINDOWS"

const CUR_PLATFORM = LINUX_PLATFORM
// const CUR_PLATFORM = WINDOWS_PLATFORM

var ip = ""
var ws_ip = ""
var ding_auth_ip = ""

if (CUR_PLATFORM==WINDOWS_PLATFORM){
	ip = "http://10.0.62.6"
	ws_ip = "ws://10.0.62.6"
	ding_auth_ip = "http://q45097i337.vicp.fun"
}else if (CUR_PLATFORM==LINUX_PLATFORM){
	ip = "https://ai.freeflyagain.xyz"
	ws_ip = "wss://ai.freeflyagain.xyz"
	ding_auth_ip = ip + ":9091"
}
const help_doc_url = "https://aq30a50dr4x.feishu.cn/docx/Fy0Zd3W0Ho7YnKx5tuHcrMD2noe?from=from_copylink"

const client_id = "dingtpowy9fi1tgg2kcr"
const tts_ip = ip + ":9090"
const gpt_ip = ip + ":9091"
const mj_ip = ip + ":9092"
const ws_url = ws_ip + ":9092/websocket/"
const sd_version = "2.3"

export {gpt_ip, tts_ip,mj_ip,ws_url, ding_auth_ip, client_id, sd_version, help_doc_url};
