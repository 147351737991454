<template>
	<div class="mj_cont" :style="{color: appear_style==0?'#000':'aliceblue',backgroundColor: appear_style==0?'#fff':'#282828'}">
		<div class="left_cont" :style="{backgroundColor: appear_style==0?'':''}">
			<el-button class="new_chat" @click="zero_chatlist_create_channel">
						New Chat<i class="el-icon-edit-outline" style="font-weight: 900;float: right;margin-left: 5px;"></i>
			</el-button>
			<div class="mj_mes_list">
				<MJRecursiveComponent @clickChatList="clickChatList" @clickSetting="clickSetting" :appear_style="appear_style" :mes_list="mes_list" :deep="0" :cur_mes_list_id="cur_mj_list_item.id"/>
			</div>
			<div class="mj_member_title" v-if="mj_channel_member_list.length>0">频道成员</div>
			<div class="mj_member_list"  :style="{color: appear_style==0?'#282828':'#bebebe'}">
				<div class="mj_member_item" v-for="(item,index) in mj_channel_member_list">
					<div class="mj_member_name">{{item.name}}</div>
				</div>
			</div>
		</div>
		<div class="mj_fold_list" @click="fold_list"><i class="el-icon-s-fold" v-show="!is_fold_list" ></i><i class="el-icon-s-unfold"  v-show="is_fold_list"></i></div>
		<div class="center_cont">
			<div class="mes_cont_list" ref="messageContainer" @scroll="handleScroll">
				<div class="mes_item_list">
					<MjMesType :appear_style="appear_style" @clickMes="clickMes" @newFavoriteSuc="newFavoriteSuc" @editCollectSubmit="editCollectSubmit" :mes_item_list="mes_item_list"/>
					<div style="height: 240px;"></div>
				</div>
			</div>
			<div class="mj_bottom">
				<div class="temple_file" :style="{color: appear_style==0?'#000':'#fff'}">
					<div style="display:inline-flex;flex-wrap: wrap;" >
						<div @mouseover="onListTempFileMouseOver(index)"
						@mouseout="onListTempFileMouseOut"
						:style="{maxWidth: item.type=='image'?'10%':'',minWidth:item.type=='image'?'10%':'10%'}" 
						style="margin-left: 10px;position: relative;" 
						v-for="(item, index) in temple_file" 
						:key="index">
							<img style="border-radius: 5px;" v-if="item.type=='image'" :src="item.content"/>
							<div v-if="item.type=='others'" style="display: flex;margin-top: 5px;">
								<div style="border: 1px solid #9a9a9a;padding: 1px;padding-left: 6px;padding-right: 6px;border-radius: 3px;"><i class="el-icon-tickets" style="background-color: #ff5500;padding: 4px;color: azure;font-size: 12px;border-radius: 3px;margin-right: 5px;"></i><p style="font-size: 10px;display: inline-flex;max-width: 60px;white-space: nowrap;overflow-x: hidden;text-overflow: ellipsis;">{{item.content}}</p></div>
							</div>
							<div v-show="index==on_list_tempfileover_index" @click="removeTempFile(index)" style="position: absolute;right: 0px;top: 0px;"><i class="el-icon-error" style="color: #9a9a9a;"></i></div>
						</div>
					</div>
				</div>
				<div class="mj_input" :style="{backgroundColor: appear_style==0?'#fff':'#282828'}">
					<div class="mj_input_before">
						<el-button @click="clickUpload" class="mj_text_but" type="success" plain size="mini" icon="el-icon-upload" style="padding: 3px;font-size: 16px;height: 100%;"></el-button>
						<input type="file" multiple style="display: none;"  @change="changeFile" id="inputFile" ref="inputFile" accept="image/*"/>
						<div class="mj_commands">
							<el-select @change="mj_cur_command_change" v-model="mj_cur_command" placeholder="请选择" size="mini" :style="{color: appear_style==0?'#000':'#fff',background: appear_style==0?'':'#121212'}">
								<el-option
									v-for="item in mj_command_options"
									:key="item.value"
									:label="item.label"
									:value="item.value">
								</el-option>
							</el-select>
						</div>
						<!-- <el-button class="mj_img_but" @click="scale" type="info" plain size="mini" icon="el-icon-full-screen"  style="padding: 3px;font-size: 16px;"></el-button> -->
					</div>
					
					<textarea class="mj_custom_input" id="mj_textarea" style="border-radius: 2px;"  @keydown="handleKeyDown" :placeholder="imaginePlaceholder"  :style="{color: appear_style==0?'#000':'#fff',background: appear_style==0?'#fff':'#121212'}"></textarea>
					<div class="mj_input_but">
						<el-button  class="mj_text_but" type="primary" @click="vueSendMes" plain size="mini" icon="el-icon-position" style="padding: 3px;font-size: 16px;"></el-button>
						<!-- <el-button  class="mj_text_but" type="success" @click="clickUpload" plain size="mini" icon="el-icon-upload" style="padding: 3px;font-size: 16px;margin-left: 10px;"></el-button> -->
						<!-- <el-button class="mj_img_but" @click="scale" type="info" plain size="mini" icon="el-icon-full-screen"  style="padding: 3px;font-size: 16px;margin-left: 10px;"></el-button> -->
						
						<el-popover
							placement="bottom"
							trigger="click">
							<div style="padding: 30px;border-radius: 10px;" :style="{color: appear_style==0?'#000':'aliceblue',backgroundColor: appear_style==0?'aliceblue':'#1f1f1f'}">
								<el-select v-model="mj_setting.mj_model_value" @change="save_mj_setting" placeholder="请选择" style="padding-bottom: 10px;">
									<el-option
									v-for="item in mj_setting.mj_model_options"
									:key="item.value"
									:label="item.label"
									:value="item.value">
									</el-option>
								</el-select>
								<div style="display: flex;">
									<el-radio style="margin-top: 5px;margin-left: 0px;display: inline;" border size="medium" v-model="mj_setting.stylize_value" @input="save_mj_setting" label="--s 50">Stylize low</el-radio>
									<el-radio style="margin-top: 5px;margin-left: 0px;display: inline;" border size="medium" v-model="mj_setting.stylize_value" @input="save_mj_setting" label="">Stylize med</el-radio>
									<el-radio style="margin-top: 5px;margin-left: 0px;display: inline;" border size="medium" v-model="mj_setting.stylize_value" @input="save_mj_setting" label="--s 250">Stylize high</el-radio>
									<el-radio style="margin-top: 5px;margin-left: 0px;display: inline;" border size="medium" v-model="mj_setting.stylize_value" @input="save_mj_setting" label="--s 750">Stylize very high</el-radio>
								</div>
								<div style="display: flex;padding: 5px;padding-top: 15px;">
									<el-checkbox v-model="mj_setting.auto_slide_to_bottom" @change="save_mj_setting">新消息自动滑动到底部</el-checkbox>
								</div>
							</div>
							<el-button slot="reference" class="mj_text_but" type="info" plain size="mini" icon="el-icon-setting"  style="padding: 3px;font-size: 16px;"></el-button>
						</el-popover>
					</div>
				</div>
			</div>
		</div>
		<div class="drag_line">
			<div class="drag_line_left"></div>
			<div class="drag_line_right"></div>
		</div>
		<div class="right_cont">
			<el-menu :default-active="active_nav_index" class="el-menu-demo" mode="horizontal" @select="handleSelect" :style="{backgroundColor: appear_style==0?'#fff':'#282828'}" text-color="#747474" active-text-color="#00aaff">
			  <el-menu-item index="1">ChatGPT</el-menu-item>
			  <el-menu-item index="2">提词器</el-menu-item>
			  <el-menu-item index="3">收藏</el-menu-item>
			  <el-menu-item index="4">频道概览</el-menu-item>
			</el-menu>
			<ChatGpt class="chatgpt" :is_fold_list="true" :wide_mode = "3" :is_require_userLogin="false" ref="chatgpt"  v-show="cur_active_nav_index=='1'" />
			<Prompter @submitPrompter="submitPrompter" class="prompter" :appear_style="appear_style" v-show="cur_active_nav_index=='2'" />
			<CollectMjMes class="prompter" :appear_style="appear_style" @collectPosition="collectPosition" ref="collectMjMes" v-show="cur_active_nav_index=='3'" />
			<ChannelPreview class="prompter" :appear_style="appear_style" @collectPosition="collectPosition" ref="channelPreview" v-show="cur_active_nav_index=='4'" />
		</div>
		<UserLogin @change="handleLogStatusChange"  @changeStyle="change_style" ref = "userLogin"></UserLogin>
		<div v-if="inpaint_show" @click="inpaint_show=false" style="width: 100%;height: 100%;background-color: rgba(28, 28, 28, 0.7);position: absolute;z-index: 300;">
			<MaskDraw :img_info="inpaint_img_info" style=" width: 60%;height:80%; position: absolute; top: 50%; left: 50%; transform: translate(-50%, -50%);"></MaskDraw>
		</div>
		<div :style="{color: appear_style==0?'#282828':'aliceblue'}">
			<el-dialog
			  title="频道设置"
			  :visible.sync="channelSetting"
			  width="30%">
			  <div style="display: inline-flex;align-items: center;">
				  <div style="width: 150px;font-size: 16px;" :style="{color: appear_style==0?'#282828':'#fff'}">频道名称：</div>
				  <el-input v-model="channel_title_input"  placeholder="请输入内容"></el-input>
				  <el-button  @click="vueModifyChatList">重命名</el-button>
			  </div>
			  <span slot="footer" class="dialog-footer" style="justify-content: center;display: flex;">
			    <el-button type="success" v-show="cur_mj_list_item.type=='0'"  @click="createChannel=true,channelSetting=false">创建频道</el-button>
			    <el-button type="danger"  @click="vueDelChatList">删除频道</el-button>
			  </span>
			</el-dialog>
			<el-dialog
			  title="创建频道"
			  :visible.sync="createChannel"
			  width="30%">
			  <div>
				  <div style="display: inline-flex;align-items: center;">
						<div style="width: 150px;font-size: 16px;">频道名称：</div>
						<el-input v-model="createChannelForm.title"  placeholder="请输入内容"></el-input>
				  </div>
				  <div style="display: inline-flex;align-items: center;line-height: 40px;">
						<div style="width: 110px;font-size: 16px;">频道类型：</div>
						<el-radio v-model="createChannelForm.type" label="0">层级频道</el-radio>
						<el-radio :disabled="cur_mj_list_item.id=='0'" v-model="createChannelForm.type" label="1">消息频道</el-radio>
				  </div>
				  <div v-if="cur_mj_list_item.id=='0'" style="display: inline-flex;align-items: center;line-height: 40px;">
						<div style="width: 110px;font-size: 16px;">是否公开：</div>
						<el-radio v-model="createChannelForm.isPublic" label="0">个人频道</el-radio>
						<el-radio v-model="createChannelForm.isPublic" label="1">公共频道</el-radio>
				  </div>
				<div v-if="createChannelForm.isPublic=='1'&&cur_mj_list_item.id=='0'" style="display: inline-flex;align-items: center;">
						<div style="width: 150px;font-size: 16px;">频道密码：</div>
						<el-input v-model="createChannelForm.password"  placeholder="请输入内容"></el-input>
				</div>
			  </div>
			  
			  <span slot="footer" class="dialog-footer">
			    <el-button  @click="createChannel=false">取消</el-button>
			    <el-button type="primary"  @click="submitCreateChannel">确定</el-button>
			  </span>
			</el-dialog>
			<el-dialog
			  title="确认加入频道"
			  :visible.sync="joinChannel"
			  width="30%">
			  <div v-show="cur_mj_list_item.type=='0'" style="display: inline-flex;align-items: center;">
				  <div style="width: 150px;font-size: 16px;">频道密码：</div>
				  <el-input v-model="createChannelForm.password"  placeholder="请输入密码"></el-input>
			  </div>
			 <span slot="footer" class="dialog-footer" >
			   <el-button  @click="joinChannel=false">取消</el-button>
			   <el-button type="primary"  @click="vueJoinChannel">确定</el-button>
			 </span>
			</el-dialog>
			<el-dialog
			  title="Custom Zoom"
			  :visible.sync="custom_zoom_show"
			  width="30%">
			  <div style="display: inline-flex;align-items: center;width: 100%;">
				  <textarea v-model="customZoomPrompt"  style="border-radius: 2px;border: 0px;width: 100%;height: 100px;"  :placeholder="imaginePlaceholder"  :style="{color: appear_style==0?'#000':'#fff',background: appear_style==0?'#e3e3e3':'#121212'}"></textarea>
			  </div>
			 <span slot="footer" class="dialog-footer" >
			   <el-button  @click="custom_zoom_show=false">取消</el-button>
			   <el-button type="primary"  @click="submitCustomZoom">确定</el-button>
			 </span>
			</el-dialog>
		</div>
	</div>
</template>

<script>
import UserLogin from "@/components/UserLogin.vue";
import MJRecursiveComponent from "./MJRecursiveComponent";
import MjMesType from "@/components/mj_mes_type/mj_mes_type";
import ChatGpt from "@/components/ChatGpt.vue";
import Prompter from "@/components/Prompter.vue";
import CollectMjMes from "@/components/CollectMjMes.vue";
import ChannelPreview from "@/components/ChannelPreview.vue";
import {getChatList,modifyChatList,delChatList,createChatList,joinPublicChatList,getChatMesList,getChatListMember,sendMjMes,mjUpload,customZoom,blend} from "@/api/mj_api";
import {addChildListById,addChildItemById,findAndModifyOrDelete} from "@/api/common_api";
import {ws_url, mj_ip, sd_version} from "@/api/environment";
import {isDiscordLink,checkAndModifyPrompt,checkSingleUrl,getMatchPrompt,checkPromptZoom} from "../utils/mj_tools.js"
import $ from 'jquery';
import {getNotice} from "@/api/common_api";
import MaskDraw from "@/components/MaskDraw.vue";

export default {
components: {
  UserLogin,
  MJRecursiveComponent,
  MjMesType,
  ChatGpt,
  Prompter,
  CollectMjMes,
  ChannelPreview,
  MaskDraw
},
data() {
	return {
		mj_setting:{
			stylize_value: '',
			auto_slide_to_bottom: true,
			mj_model_value:"",
			mj_model_options: [
			{
				value: '',
				label: '默认模型 (和官网一致)'
			}, {
				value: '--v 6.1',
				label: 'Midjourney Model V6.1'
			}, {
				value: '--v 6',
				label: 'Midjourney Model V6 [ALPHA]'
			}, {
				value: '--niji 6',
				label: 'Niji Model V6 [ALPHA]'
			}, {
				value: '--v 5.2',
				label: 'Midjourney Model V5.2'
			}, {
				value: '--v 5.1',
				label: 'Midjourney Model V5.1'
			}, {
				value: '--niji 5',
				label: 'Niji Model V5'
			}, {
				value: '--v 5',
				label: 'Midjourney Model V5.0'
			}, {
				value: '--niji 4',
				label: 'Niji Model V4'
			}, {
				value: '--v 4',
				label: 'Midjourney Model V4'
			}, {
				value: '--v 3',
				label: 'Midjourney Model V3'
			}, {
				value: '--v 2',
				label: 'Midjourney Model V2'
			}, {
				value: '--v 1',
				label: 'Midjourney Model V1'
			}]
		},
		on_list_tempfileover_index:-1,
		temple_file:[],		// {type:"others/image",content:""}
		customZoomPrompt:"",
		cur_custom_zoom_obj:{},
		custom_zoom_show:false,
		inpaint_show:false,
		inpaint_img_info:{},
		cur_mj_list_item:{
			id:"0"
		},
		channelSetting:false,
		createChannel:false,
		joinChannel:false,
		createChannelForm:{
			title:"",
			type:"0",
			isPublic:"0",
			password:"",
		},
		channel_title_input:"",
		mes_list: [],
		mes_item_list:[],
		mj_command_options: [
			{
			  value: 'imagine',
			  label: 'imagine',
			  placeholder: "请输入提示词"
			}, {
			  value: 'describe',
			  label: 'describe',
			  placeholder: "请输入外网url"
			}, {
			  value: 'blend',
			  label: 'blend',
			  placeholder: "上传文件后回车提交"
			}
		],
		mj_channel_member_list: [],
		mj_cur_command: 'imagine',
		active_nav_index: '2',
		cur_active_nav_index: '2',
		is_fold_list:false,
		websocket: null,
		heartbeatInterval: 30000, // 心跳间隔，单位为毫秒
		file_list:[],
		mes_loading: false, // 加载状态
		distance_from_bottom: 0, // 距离底部的距离
		page_size:10,		// 加载消息的数量
		page_num:1,		// 加载消息的数量
		appear_style:0,		// 外观样式
		is_sending_mes:false,
		temp_channel: {
			id: "0",
			title: "临时频道",
			type: 1,	//0表示子频道，1表示消息频道
			isPublic: 0,
			isModify:false,	//用户是否有修改和删除的权限
			child: []
		},
		collect_mj_mes:null,
		temp_mes_item_list:[] 	//为了解决load_more时的mes_item_list问题；
	};
},
mounted() {
	this.init()
},
beforeDestroy() {
	this.closeWebSocket();
	window.removeEventListener('message', this.handleInpaintMessage, false);

},
computed: {
	imaginePlaceholder() {
		// 找到value为'imagine'的对象，并返回它的placeholder
		const option = this.mj_command_options.find(opt => opt.value === this.mj_cur_command);
		return option ? option.placeholder : '';
	}
},
methods: {
	init(){
		this.restoreSetting()
		this.dragLine();
		setTimeout(()=>{
			try{
				this.$refs.chatgpt.scale(3);
				this.$refs.chatgpt.scroll_bottom()
			}catch(e){}
		},1000)
		setTimeout(()=>{
			try{
				this.$refs.chatgpt.scale(3);
				this.$refs.chatgpt.scroll_bottom()
			}catch(e){}
		},3000)
		this.vueGetChatList()
		this.connectWs(true)
		this.mj_input_change()
		this.get_appear_style()
		window.addEventListener('message', this.handleInpaintMessage, false);
		if(localStorage.getItem("mj_setting")){
			this.mj_setting = JSON.parse(localStorage.getItem("mj_setting"))
		}
	},
	restoreSetting(){
		console.log("sd_version")
		console.log(localStorage.getItem("sd_version"))
		console.log(sd_version)
		if(!localStorage.getItem("sd_version")||localStorage.getItem("sd_version")!=sd_version){
			console.log("重置")
			localStorage.setItem("sd_version", sd_version)
			localStorage.removeItem("mj_setting");
			localStorage.removeItem("local_mj_prompt");
		}
	},
	newFavoriteSuc(){
		this.$refs.collectMjMes.getCollectList()
	},
	editCollectSubmit(org_mj_collect_list, mj_collect_list){
		var cur_collect_id = this.$refs.collectMjMes.getCurCollectId()
		
		var old_item = org_mj_collect_list.find(function(element) {
		    return element.id === cur_collect_id;
		});
		
		var new_item = mj_collect_list.find(function(element) {
		    return element.id === cur_collect_id;
		});
		if (old_item&&new_item&&old_item.checked!=new_item.checked){
			 this.$refs.collectMjMes.getCollectMesList("",cur_collect_id,1,14)
		}
	},
	submitPrompter(prompter){
		$("#mj_textarea").val(prompter)
		this.vueSendMes()
	},
	save_mj_setting(){
		localStorage.setItem("mj_setting", JSON.stringify(this.mj_setting))
	},
	mj_cur_command_change(){
		this.file_list=[]
		this.temple_file=[]
	},
	removeTempFile(index){
		this.file_list.splice(index, 1)
		this.temple_file.splice(index, 1)
		this.on_list_tempfileover_index=-1
		console.log(this.file_list)
	},
	
	submitCustomZoom(){
		var that=this
		var prompt = checkPromptZoom(this.customZoomPrompt)
		customZoom(localStorage.getItem("user_token"), this.cur_custom_zoom_obj.mesId, prompt).then(response => {
			if(response.status==200){
				this.$message({
					message: "提交成功，请稍等片刻 (≧◡≦) ♡",
					type: 'success'
				});
				that.custom_zoom_show=false
			}else{
				this.$message({
					message: response.res,
					type: 'warning'
				});
			}
		})
	},
	 handleInpaintMessage(event) {
	  if(event.data.type=="inpaint_submit_res"){
		  var submit_res_status = event.data.content
		  if(submit_res_status.status==200){
			  this.$message({
			  	message: "提交成功，请稍等片刻 (≧◡≦) ♡",
			  	type: 'success'
			  });
			  this.inpaint_show = false
		  }else{
			  this.$message({
			  	message: submit_res_status.res,
			  	type: 'warning'
			  });
		  }
	  }
	},
	clickMes(img_url,mes_item,component){
		this.connectWs(false)
		// this.mes_item_list.push({
		// 	id:null,
		// 	role:0,
		// 	type:0,
		// 	mesOption: component.label,
		// 	mes: (component.label ? component.label : ''+(component.emoji ? component.emoji.name : '')),
		// 	imgUrls:[],
		// 	discordUrls:[]
		// })
		// var that=this
		// requestAnimationFrame(() => {
		// 	that.scroll_bottom()
		// });
		if (component.label == "Vary (Region)"){
			this.inpaint_show=true
			this.inpaint_img_info.imageURL = img_url
			this.inpaint_img_info.mesId = mes_item.mesId
			this.inpaint_img_info.prompt = getMatchPrompt(mes_item.mes)
			this.inpaint_img_info.userToken = localStorage.getItem("user_token")
			this.inpaint_img_info.mj_ip = mj_ip
		}else if(component.label == "Custom Zoom"){
			this.custom_zoom_show = true
			this.cur_custom_zoom_obj.mesId = mes_item.mesId
			console.log("checkPromptZoom(getMatchPrompt(mes_item.mes))")
			console.log(checkPromptZoom(getMatchPrompt(mes_item.mes)))
			this.customZoomPrompt = checkPromptZoom(getMatchPrompt(mes_item.mes))
		}
	},
	containsVOrNiji(text) {
	  const pattern = /--v\b|--niji\b/;
	  return pattern.test(text);
	},
	containsS(text) {
	  const pattern = /--s\b/;
	  return pattern.test(text);
	},
	checkSetting(){
		var send_text = $("#mj_textarea").val();
		
		$("#mj_textarea").val(checkAndModifyPrompt(send_text))
		
		if(this.mj_setting.stylize_value!=""&&!this.containsS(send_text)){
			$("#mj_textarea").val(send_text+" "+this.mj_setting.stylize_value)
		}
		var send_text = $("#mj_textarea").val();
		if(this.mj_setting.mj_model_value!=""&&!this.containsVOrNiji(send_text)){
			$("#mj_textarea").val(send_text+" "+this.mj_setting.mj_model_value)
		}
	},
	
	get_appear_style(){
		if(localStorage.getItem("appear_style")==null){
			this.appear_style=0
		}else{
			this.appear_style=Number(localStorage.getItem("appear_style"))
		}
		this.change_element_ui_style(this.appear_style)
	},
	change_style(value){
		this.appear_style=value
		this.$refs.chatgpt.change_style(value)
		this.change_element_ui_style(this.appear_style)
	},
	change_element_ui_style(value){
		if(value==0){
			$(".el-input__inner").css("color", "#000")
			$(".el-dialog").css("background", "#fff")
			$(".el-dialog__title").css("color", "#282828")
			$(".el-dialog__body").css("color", "#282828")
			$(".el-input-number__decrease, .el-input-number__increase").css("background", "#f8f8f8")
			$(".el-input-number__decrease, .el-input-number__increase").css("color", "#000")
		}else if(value==1){
			$(".el-input__inner").css("color", "#fff")
			$(".el-dialog").css("background", "#282828")
			$(".el-dialog__title").css("color", "#fff")
			$(".el-dialog__body").css("color", "#fff")
			$(".el-input-number__decrease, .el-input-number__increase").css("background", "#4c4e51")
			$(".el-input-number__decrease, .el-input-number__increase").css("color", "#fff")
		}
	},
	handleLogStatusChange(){
		this.init()
		// chatgpt也要触发
		this.$refs.chatgpt.handleLogStatusChange()
		this.newFavoriteSuc()
	},
	handleScroll() {
		const container = this.$refs.messageContainer;
		if (container.scrollTop === 0 && !this.mes_loading) {
			this.distance_from_bottom = container.scrollHeight - container.scrollTop;
			this.loadMoreMessages();
		}
	},
	loadMoreMessages() {
		if (this.mes_item_list.length==0){
			return;
		}
		console.log("loadMoreMessages")
		// 模拟异步加载消息
		// 这里你可以替换成你的消息加载逻辑
	
		setTimeout(() => {
			this.page_num+=1
			this.temp_mes_item_list = JSON.parse(JSON.stringify(this.mes_item_list))
			this.mes_item_list = []
			this.vueGetChatMesList(localStorage.getItem("user_token"),this.cur_mj_list_item.id, this.page_num, this.page_size,false, "load_more");
			setTimeout(() => {
				this.$nextTick(() => {
					const container = this.$refs.messageContainer;
					container.scrollTop = container.scrollHeight - this.distance_from_bottom;
				});
			}, 500)
		},500)
	},
	onListTempFileMouseOver(index){
		this.on_list_tempfileover_index = index
		console.log(index)
	},
	onListTempFileMouseOut(){
		this.on_list_tempfileover_index = -1
	},
	vueMjUpload(){
		var that=this
		if(this.file_list.length>0){
			this.$message({
				message: "上传中，稍等片刻！(=^･ω･^=)ฅ",
				type: 'success'
			});
			
			mjUpload(this.file_list,localStorage.getItem("user_token"), this.cur_mj_list_item.id).then(response => {
				if(response.status==200){
					console.log(response.res)
					that.receiveMessages(response.res)
				}else{
					this.$message({
						message: response.res,
						type: 'warning'
					});
				}
			})
			that.file_list = []
			document.getElementById('inputFile').value = ''; // 清空文件输入框的值
		}
	},
	async blendUploadFile(event) {
		console.log(event)
		var that=this
		for (let file of event.target.files) {
			console.log("file2")
			console.log(file)
			// 创建一个FileReader对象
			 let result = await new Promise((resolve, reject) => {
				let reader = new FileReader();
				reader.onload = e => {
					if (file.type.substring(0, 5) == 'image') {
						resolve({ type: "image", content: e.target.result });
					} else {
						resolve({ type: "others", content: file.name });
					}
				};
				reader.onerror = reject;
				reader.readAsDataURL(file);
			});
	
			that.temple_file.push(result);
		}

		console.log("temple_file")
		console.log(this.temple_file)
		document.getElementById('inputFile').value = ''; // 清空文件输入框的值
	},
	async changeFile(event) {
		if(this.cur_mj_list_item.type=='0'||this.cur_mj_list_item.id=='0'){
			this.$message({
				message: "你当前不在消息频道,请先创建或进入消息频道！",
				type: 'warning'
			});
			return
		}
		var that=this
		if(event.target.files.length>5){
			this.$message({
				message: '最多上传5个文件！',
				type: 'warning'
			});
			return
		}else{
			for (let file of event.target.files) {
				console.log("file")
				console.log(file)
				const fileSizeInMB = file.size / (1024 * 1024);
				if (fileSizeInMB > 20) {
					this.$message({
						message: '文件大小超过限制!（最大20M）',
						type: 'warning'
					});
					that.file_list = []
					return
				} else {
					that.file_list.push(file)
				}
			}
		}
		console.log("fileList")
		console.log(this.file_list)
		if(this.mj_cur_command=='blend'){
			await this.blendUploadFile(event)
		}else{
			this.vueMjUpload()
		}
	},
	
	connectWs(reFresh) {
		if (this.websocket && this.websocket.readyState === WebSocket.OPEN && !reFresh) {
			console.log('WebSocket already connected.');
			return;
		}
		var user_token = localStorage.getItem("user_token")
		this.websocket = new WebSocket(ws_url+user_token);
		
		this.websocket.onopen = () => {
			console.log("Connected to the server");
			this.sendHeartbeat(user_token);
		};

		this.websocket.onmessage = (event) => {
			this.receiveMessages(event.data);
			// console.log(`Message received from server: ${event.data}`);
		};

		this.websocket.onclose = () => {
			console.log("Connection closed");
		};

		this.websocket.onerror = (error) => {
			console.error(`WebSocket error: ${error}`);
		};
	},
	sendHeartbeat(user_token) {
		setInterval(() => {
			if (this.websocket.readyState === WebSocket.OPEN) {
				// 获取当前时间戳
				var timestamp = Date.now();
				
				// 创建一个新的 Date 对象
				var date = new Date(timestamp);
				
				// 获取直观的日期和时间格式
				var year = date.getFullYear();
				var month = (date.getMonth() + 1).toString().padStart(2, '0'); // 月份从 0 开始，需要加 1
				var day = date.getDate().toString().padStart(2, '0');
				var hours = date.getHours().toString().padStart(2, '0');
				var minutes = date.getMinutes().toString().padStart(2, '0');
				var seconds = date.getSeconds().toString().padStart(2, '0');
				
				// 格式化日期和时间
				var formattedDate = `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
				
				// console.log(formattedDate);
				// console.log("send heart!")
				this.websocket.send(JSON.stringify({ type: "ping", content: "", user_token: user_token }));
			}
		}, this.heartbeatInterval);
	},
	// sendMessage() {
	// 	if (this.websocket.readyState === WebSocket.OPEN) {
	// 		this.websocket.send(JSON.stringify({ type: "mes", content: this.messageToSend, user_token: this.$store.state.PYTHON_WS_UID }));
	// 		console.log(`Message sent to the server: ${$("#mj_textarea").val()}`);
	// 		$("#mj_textarea").val("")
	// 	} else {
	// 		console.error("WebSocket connection is not open. Unable to send message.");
	// 	}
	// },
	scroll_bottom(){
		// 获取指定元素
		try{
			var cont_rig = document.getElementsByClassName('mes_cont_list')[0]; // 替换为你的元素的ID
			if(this.last_scroll_bottom_pos!=cont_rig.scrollHeight){
				// 滚动到元素底部
				cont_rig.scrollTop = cont_rig.scrollHeight;
			}
			this.last_scroll_bottom_pos=cont_rig.scrollHeight
		}catch{}
	},
	pos_bottom(){
		// 获取指定元素
		console.log("cont_rig")
		try{
			var cont_rig = document.getElementsByClassName('mes_cont_list')[0]; // 替换为你的元素的ID
			console.log(cont_rig)
			// 定位到元素底部
			cont_rig.scrollTo({
				top: cont_rig.scrollHeight,
				behavior: 'instant' // 或者使用 smooth
			});
		}catch{}
	},
	receiveMessages(data) {
		console.log(`Message received from server: ${data}`);
		var that=this
		var mes_item=JSON.parse(data)
		console.log(mes_item.mjListId)
		if(mes_item.mjListId==this.cur_mj_list_item.id){
			let found = false;
			this.mes_item_list.forEach((item, index) => {
			    if (item.mesId === mes_item.mesId) {
			        // 如果找到相同 mesId 的元素，进行替换操作
					this.mes_item_list.splice(index, 1, mes_item);
			        found = true;
			    }
			});
			
			// 如果没找到相同 mesId 的元素，进行添加操作
			if(this.mj_setting.auto_slide_to_bottom){
				if (!found) {
					this.mes_item_list.push(mes_item)
					setTimeout(()=>{
						requestAnimationFrame(() => {
							that.scroll_bottom()
						});
					},500)
				}
				if(mes_item.type==0){
					setTimeout(()=>{
						requestAnimationFrame(() => {
							that.scroll_bottom()
						});
					},500)
				}
			}else{
				if (!found) {
					this.mes_item_list.push(mes_item)
				}
			}
		}
	// WebSocket.onmessage will handle incoming messages
	},
	closeWebSocket() {
		if (this.websocket) {
			this.websocket.close();
			console.log("WebSocket connection closed");
		}
	},
	handleKeyDown(event) {
		if (event.key === "Enter" && event.shiftKey) {
		// 如果按下 Shift + Enter，则换行
			this.inputText += "\n";
		} else if (event.key === "Enter") {
		// 如果只按下 Enter，则执行提交逻辑
			event.preventDefault(); // 阻止默认的 Enter 换行行为
			this.vueSendMes()
		}
	},
	mj_input_change(){
		$("#mj_textarea").on("input", function() {
			this.style.height = "auto";
			this.style.height = (this.scrollHeight-18) + "px";
			this.style.height = (this.scrollHeight) + "px";
		});
	},
	clickUpload(){
		// $('#inputFile').click()
		this.$refs.inputFile.click()
	},
	vueSendBlend(){
		var that=this
		if(this.file_list.length>=2&&this.file_list.length<=5){
			this.$message({
				message: "上传中，稍等片刻！(=^･ω･^=)ฅ",
				type: 'success'
			});
			
			blend(this.file_list, localStorage.getItem("user_token"), this.cur_mj_list_item.id).then(response => {
				if(response.status==200){
					console.log(response.res)
					that.receiveMessages(response.res)
					this.is_sending_mes=false
				}else{
					this.$message({
						message: response.res,
						type: 'warning'
					});
					this.is_sending_mes=false
				}
			})
			that.file_list = []
			that.temple_file=[]
			document.getElementById('inputFile').value = ''; // 清空文件输入框的值
		}else{
			this.$message({
				message: "请提交2到5张图片！",
				type: 'warning'
			});
			this.is_sending_mes=false
		}
	},
	vueSendMjMes(){
		sendMjMes(localStorage.getItem("user_token"), $("#mj_textarea").val(), this.cur_mj_list_item.id, this.mj_cur_command).then(response => {
			console.log('response.status')
			console.log(response.status)
			if(response.status==200){
				console.log(response.res)
				this.$message({
					message: "提交成功，请稍等片刻 (≧◡≦) ♡",
					type: 'success'
				});
				this.is_sending_mes=false
			}else{
				this.$message({
					message: response.res,
					type: 'warning'
				});
				this.is_sending_mes=false
			}
		})
		$("#mj_textarea").val('')
		$("#mj_textarea")[0].style.height="auto"
	},

	vueSendMes(){
		var that=this
		getNotice(this)
		
		if(this.is_sending_mes){
			this.$message({
				message: "消息发送中！",
				type: 'warning'
			});
			return
		}
		if(this.mj_cur_command=='imagine'){
			this.checkSetting()
		}
		this.is_sending_mes = true
		if(this.mj_cur_command=='imagine'&&!checkSingleUrl($("#mj_textarea").val())){
			this.$message({
				message: "imagine模式垫图不能只上传一个图片链接，要加些描述！",
				type: 'warning'
			});
			this.is_sending_mes=false
			return
		}
		if(this.cur_mj_list_item.type=='0'||this.cur_mj_list_item.id=='0'){
			this.$message({
				message: "你当前不在消息频道,请先创建或进入消息频道！",
				type: 'warning'
			});
			this.is_sending_mes=false
			return
		}
		var send_text =$("#mj_textarea").val();
		requestAnimationFrame(() => {
			that.scroll_bottom()
		});
		if(send_text==""&&this.mj_cur_command!='blend'){
			this.$message({
				message: "内容不能为空！",
				type: 'warning'
			});
			this.is_sending_mes=false
			return
		}
		if(send_text.length>=6000){
			this.$message({
				message: "文本内容超过最大限制！",
				type: 'warning'
			});
			this.is_sending_mes=false
			return
		}
		if(this.mj_cur_command=='imagine'){
			
		}else if(this.mj_cur_command=='describe'){
			// if(!isDiscordLink(send_text)){
			// 	this.$message({
			// 		message: "请输入discord链接！",
			// 		type: 'warning'
			// 	});
			// 	this.is_sending_mes=false
			// 	return
			// }
		}
		if('nickname' in this.$refs.userLogin.user){
			that.mes_item_list.push({
				id:null,
				role:0,
				type:0,
				username:this.$refs.userLogin.user.nickname,
				mesOption: this.mj_cur_command,
				mes:send_text,
				imgUrls:[],
				discordUrls:[]
			})
		}
		
		this.connectWs(false)
		
		if(this.mj_cur_command=='blend'){
			this.vueSendBlend()
		}else{
			this.vueSendMjMes()
		}
	},
	vueJoinChannel(){
		var that=this
		joinPublicChatList(localStorage.getItem("user_token"),this.cur_mj_list_item.id,this.createChannelForm.password).then(response => {
			if(response.status==200){
				that.joinChannel=false
				that.clickChatList(that.cur_mj_list_item)
				this.$message({
					message: response.res,
					type: 'success'
				});
			}else{
				this.$message({
					message: response.res,
					type: 'warning'
				});
			}
		})
	},
	collectPosition(mj_mes){
		console.log("mj_mes")
		console.log(mj_mes)
		var that = this
		this.temp_channel.id = mj_mes.mjListId
		this.collect_mj_mes=mj_mes
		this.mes_item_list=[]
		this.page_num = 0
		console.log(this.temp_channel)
		//判断临时频道是否已存在
		let exists = this.mes_list.some(item => item.title === this.temp_channel.title && item.type === 1);
		if (!exists) {
		    this.mes_list.push(this.temp_channel);
		} else {
		    console.log("该频道已经存在于 this.mes_list 中");
		}
		
		this.cur_mj_list_item = this.temp_channel
		this.mes_loading = true
		setTimeout(()=>{
			this.mes_item_list=[mj_mes]
			setTimeout(()=>{
				this.mes_loading = false
			},500)
		},1)
	},
	submitCreateChannel(){
		if(this.createChannelForm.title==""){
			this.$message({
				message: "频道名称不能为空！",
				type: 'warning'
			});
			return
		}
		var is_require_pd;
		if(this.cur_mj_list_item.id=='0' && this.createChannelForm.isPublic=='1'){
			is_require_pd='1';
		}else{
			is_require_pd='0';
		}
		if(this.cur_mj_list_item.id=='0'){
			if(this.createChannelForm.isPublic=='1' && this.createChannelForm.password==''){
				this.$message({
					message: "密码不能为空！",
					type: 'warning'
				});
				return
			}else if(this.createChannelForm.isPublic=='1' && this.createChannelForm.password.length<4){
				this.$message({
					message: "密码过于简单！",
					type: 'warning'
				});
				return
			}
			
			createChatList(
			localStorage.getItem("user_token"),
			this.cur_mj_list_item.id,
			this.createChannelForm.type,
			this.createChannelForm.title,
			this.createChannelForm.password,
			is_require_pd,
			this.createChannelForm.isPublic,
			).then(response => {
			if(response.status==200){
				this.createChannel=false
				this.$message({
					message: "频道创建成功！",
					type: 'success'
				});
				var new_mj_list_item = {
					id: response.res,
					title: this.createChannelForm.title,
					type: this.createChannelForm.type,	// 0表示子频道，1表示消息频道
					isPublic: this.createChannelForm.isPublic,
					isModify: true,	// 用户是否有修改和删除的权限
					child: []
				}
				this.mes_list.push(new_mj_list_item)
				this.cur_mj_list_item = new_mj_list_item
				this.mes_item_list=[]
			}else{
				this.$message({
					message: response.res,
					type: 'warning'
				});
			}
			})
		}else{
			createChatList(
			localStorage.getItem("user_token"),
			this.cur_mj_list_item.id,
			this.createChannelForm.type,
			this.createChannelForm.title,
			this.createChannelForm.password,
			is_require_pd,
			this.cur_mj_list_item.isPublic).then(response => {
			if(response.status==200){
				this.createChannel=false
				this.$message({
					message: "频道创建成功！",
					type: 'success'
				});
				var new_mj_list_item = {
					id: response.res,
					title: this.createChannelForm.title,
					type: this.createChannelForm.type,	//0表示子频道，1表示消息频道
					isPublic:this.cur_mj_list_item.isPublic,
					isModify:true,	//用户是否有修改和删除的权限
					child: []
				}
				addChildItemById(this.mes_list,this.cur_mj_list_item.id,new_mj_list_item)
				this.cur_mj_list_item = new_mj_list_item
				this.mes_item_list=[]
			}else{
				this.$message({
					message: response.res,
					type: 'warning'
				});
			}
			})
		}
	},
	zero_chatlist_create_channel(){
		this.cur_mj_list_item={
			id:"0"
		}
		this.createChannel = true
	},
	vueModifyChatList(){
		var that=this
		modifyChatList(localStorage.getItem("user_token"),this.cur_mj_list_item.id,this.channel_title_input).then(response => {
			if(response.status==200){
				findAndModifyOrDelete(that.mes_list, this.cur_mj_list_item.id,this.channel_title_input)
				this.channelSetting=false
				this.$message({
					message: response.res,
					type: 'success'
				});
			}else{
				this.channelSetting=false
				this.$message({
					message: response.res,
					type: 'warning'
				});
			}
		})
	},
	vueDelChatList(){
		var that=this
		delChatList(localStorage.getItem("user_token"),this.cur_mj_list_item.id).then(response => {
			if(response.status==200){
				findAndModifyOrDelete(that.mes_list, this.cur_mj_list_item.id)
				this.channelSetting=false
				this.$message({
					message: response.res,
					type: 'success'
				});
				this.cur_mj_list_item = { id:"0"}
				this.mes_item_list = []
			}else{
				this.$message({
					message: response.res,
					type: 'warning'
				});
			}
		})
	},
	clickSetting(item){
		this.channelSetting=true
		this.channel_title_input = item.title
		this.cur_mj_list_item = item
	},
	clickChatList(item){
		var that=this
		that.cur_mj_list_item = item
		that.mes_item_list = []
		this.page_num=1
		
		if(item.type=='0'){
			getChatList(localStorage.getItem("user_token"),item.id).then(response => {
				if(response.status==200){
					if(response.res.length>0){
						console.log(that.mes_list)
						// that.mes_list = response.res
						addChildListById(that.mes_list,item.id,response.res)
					}
					
				}else if(response.status==300){
					this.joinChannel=true
				}else{
					this.$message({
						message: response.res,
						type: 'warning'
					});
				}
			})
		}else if(item.type=='1'){
			this.vueGetChatMesList(localStorage.getItem("user_token"),item.id,1,this.page_size,true, "primary")
			this.$refs.channelPreview.clickChat(item.id)
		}
	},
	// type: 如果type是primary则就是覆盖加载，如果type是load_more就是追加
	vueGetChatMesList(userToken,chatListId,pageNum,pageSize,needScrollBottom,type){
		if(chatListId=='0'){
			return
		}
		var that=this
		this.mes_loading = true;
		getChatMesList(userToken,chatListId,"",0,pageNum,pageSize).then(response => {
			that.mes_loading = false;
			if(response.status==200){
				console.log(response.res)
				var new_list = response.res.channelMjMesList
				if(type=="primary"){
					that.mes_item_list = new_list
					//如果是临时频道就加上收藏的消息
					if(that.cur_mj_list_item==that.temp_channel){
						that.mes_item_list.push(that.collect_mj_mes)
					}
					
				}else if(type=="load_more"){
					const nonDuplicateItems = new_list.filter(newItem => 
					    !that.mes_item_list.some(mesItem => mesItem.id === newItem.id)
					);
					// 使用扩展运算符将不重复的元素一次性添加到mes_list的前面
					that.mes_item_list = [...nonDuplicateItems, ...that.temp_mes_item_list];
				}
				
				
				that.vueGetChatListMember(userToken,chatListId)
				if(needScrollBottom){
					requestAnimationFrame(() => {
						that.pos_bottom()
					});
					setTimeout(()=>{
						requestAnimationFrame(() => {
							that.pos_bottom()
						});
					},500)
				}
			}else if(response.status==1206){
				this.joinChannel=true
			}else{
				this.$message({
					message: response.res,
					type: 'warning'
				});
			}
		})
	},
	vueGetChatListMember(userToken,chatListId){
		var that=this
		getChatListMember(userToken,chatListId).then(response => {
			if(response.status==200){
				that.mj_channel_member_list = response.res
			}else{
				this.$message({
					message: response.res,
					type: 'warning'
				});
			}
		})
	},
	vueGetChatList(){
		var that=this
		getChatList(localStorage.getItem("user_token"),0).then(response => {
			if(response.status==200){
				if(response.res.length>0){
					console.log(that.mes_list)
					that.mes_list = response.res
				}
			}else{
				this.$message({
					message: response.res,
					type: 'warning'
				});
			}
		})
	},
	fold_list(){
		this.is_fold_list = !this.is_fold_list
		var left_cont = $('.left_cont');
		console.log(left_cont[0].style)
		if(this.is_fold_list){
			left_cont[0].style.maxWidth = '0px';
			left_cont[0].style.minWidth = '0px';
		}else{
			left_cont[0].style.maxWidth = '300px';
			left_cont[0].style.minWidth = '200px';
		}
		this.dragline_adjust()
	},
	handleSelect(key, keyPath) {
		this.cur_active_nav_index = key
	},
	dragline_adjust(){
		var rightPane = $('.right_cont');
		var chatGpt = $('.chatgpt')
		
		if(Math.abs(chatGpt.width()-rightPane.width())>4){
			chatGpt.width(rightPane.width())
		}
	},
	dragLine(){
		$(document).ready(function () {
			// 获取相关元素
			var container = $('.mj_cont');
			var dragLine = $('.drag_line');
			var rightPane = $('.right_cont');
			var chatGpt = $('.chatgpt');
			var leftPane = $('.center_cont');
			// 拖拽线的初始位置和容器的初始宽度
			var dragStartX;
			var containerWidth;
			var leftPaneWidth;
			var rightPaneWidth;
		
			// 初始化
			chatGpt.width(rightPane.width());
		
			// 鼠标和触控开始事件处理函数
			function onStart(event) {
				if (event.cancelable) {
					event.preventDefault(); // 防止选中文本
				}
				dragStartX = (event.type === 'mousedown') ? event.clientX : event.touches[0].clientX; // 获取开始位置
		
				containerWidth = container.width(); // 容器的宽度
				leftPaneWidth = leftPane.width();  // 左侧容器的宽度
				rightPaneWidth = rightPane.width(); // 右侧容器的宽度
		
				// 鼠标和触控移动、松开事件监听
				$(document).on('mousemove touchmove', onMouseMove);
				$(document).on('mouseup touchend', onEnd);
			}
		
			// 鼠标和触控移动事件处理函数
			function onMouseMove(event) {
				var currentX = (event.type === 'mousemove') ? event.clientX : event.touches[0].clientX; // 获取当前移动位置
				var offsetX = currentX - dragStartX; // 计算移动距离
		
				var newLeftPaneWidth = leftPaneWidth + offsetX;
				var newRightPaneWidth = rightPaneWidth - offsetX;
		
				// 更新左侧容器和右侧容器的宽度
				leftPane.width(newLeftPaneWidth);
				rightPane.width(newRightPaneWidth);
				chatGpt.width(rightPane.width());
		
				// 确保容器宽度不超过容器的宽度
				if (newLeftPaneWidth < 0) {
					leftPane.width(0);
					rightPane.width(containerWidth);
				} else if (newRightPaneWidth < 0) {
					leftPane.width(containerWidth);
					rightPane.width(0);
				}
			}
		
			// 鼠标和触控松开事件处理函数
			function onEnd() {
				// 移除鼠标和触控移动、松开事件监听
				$(document).off('mousemove touchmove', onMouseMove);
				$(document).off('mouseup touchend', onEnd);
			}
		
			// 绑定鼠标和触控开始事件
			dragLine.on('mousedown touchstart', onStart);
		});
	},
	
}
};
</script>

<style>
.mj_cont{
	position: absolute;
	width: 100%;
	height: 100%;
	display: inline-flex;
	overflow: hidden;
	
	.left_cont{
		box-shadow: 0.4px 0px #7d7d7d;
		height: 100%;
		padding-top: 5px;
		min-width: 200px;
		overflow: hidden;
		
		.new_chat{
			width: 95%;
			height: 45px;
			background-color: #55557f;
			color: aliceblue;
			font-weight: 600;
			display: flex;
			justify-content: center;
			align-items: center;
			border-radius: 10px;
			margin-left: 3px;
		}
		
		.mj_mes_list{
			width: 93%;
			margin: 0px 10px 0px 6px;
			padding: 3px 10px 20px 10px;
			font-weight: 550;
			font-size: 15px;
			height: 50%;
			overflow-y: scroll;
			
		}
		.mj_member_title{
			padding: 10px;
			text-align: center;
			font-weight: 600;
			margin-top: 20px;
			border-top: 1px solid #878787;
		}
		.mj_member_list{
			padding: 3px 10px 20px 5px;
			font-weight: 550;
			font-size: 15px;
			height: 20%;
			overflow-y: scroll;
			
			.mj_member_item{
				padding: 10px;
				box-shadow: 0.4px 0.2px #000, -0.4px -0.2px #aaaa7f;
				margin-top: 5px;
				
				.mj_member_name{
					text-align: center;
				}
			}
		}
	}
	
	.mj_fold_list{
		width: 30px;
		height: 30px;
		font-size: 12px;
		margin-top: 10px;
		text-align: center;
		line-height: 30px;
		border-radius: 0px 3px 10px 0px;
		box-shadow: 1px 1px #959595;
	}
	
	.center_cont{
		/* background-color: #dfdfdf; */
		height: 100%;
		padding: 20px 0px 0px 0px;
		position: relative;
		display: block;
		width: 60%;
		overflow-x: hidden;
		
		.mes_cont_list{
			width: 100%;
			height: calc(100%-50px);
			overflow-y: scroll;
			overflow-x: hidden;
			
			.mes_item_list{
				height: 100%;
				max-width: 620px;
				padding-right: 30px;
			}
		}
	
		.mj_bottom{
			position: absolute;
			bottom: 50px;
			display: flex;
			flex-direction: column; /* 使子元素垂直排列 */
			width: calc(100%-15px);
			
			.temple_file{
				width: 100%;
				padding: 5px;
				margin-bottom: 10px;
				border-radius: 10px;
				background-color: transparent;
			}
			
			.mj_input{
				width: 90%;
				border: 2px solid #ccc;
				padding: 15px 10px 10px 10px;
				border-radius: 15px;
				margin-bottom: 20px;
				justify-content: space-around;
				display: inline-flex;
				max-width: 660px;
				
				
				
				.mj_input_before{
					display: inline-flex;
					padding: 10px 0px 10px 3px;
					max-height: 30px;
					align-self: end;
					
					.mj_input_but{
						background-color: transparent;
						color: aliceblue;
					}
					.mj_commands{
						width: 100px;
						line-height: 1.5; /* 例如，设置为1.5倍行高 */
						margin: 0px 5px 0px 5px;
					}
				}
				
				
				.mj_custom_input{
					height: 28px;
					width: 95%;
					max-height: 180px;
					border: 0px;
					font-size: 16px;
					background-color: #fff;
					resize:none;
					font-weight: 600;
					padding-left: 10px;
					align-self: center;
				}
				
				.mj_input_but{
					display: inline-flex;
					padding: 10px;
					justify-content: center;
					max-height: 30px;
					align-self: end;
					
					.mj_text_but{
						background-color: transparent;
					}
					
					.mj_input_but{
						background-color: transparent;
						color: aliceblue;
					}
				}
			}
		}
	}
	.drag_line {
		position: relative;
		width: 5px;
		min-width: 5px;
		cursor: ew-resize;
		background-color: transparent;
		margin: 20px 0px 0px 0px;
		border-left: 1px solid #566573;
		
		.drag_line_left{
			position: absolute;
			width: 1px;
			height: 60px;
			background-color: #566573;
			right: 9px;
			top: 40%;
		}
		.drag_line_right{
			position: absolute;
			width: 1px;
			height: 60px;
			background-color: #566573;
			left: 3px;
			top: 40%;
		}
	}
	.drag_line:hover {
	    background-color: transparent;
	}
	.right_cont {
		height: 100%;
		flex-grow: 1;
		width: 50%;
		
		.chatgpt{
			width: 100%;
			height: calc(100% - 94px);
			overflow-x: hidden;
			top:62px;
		}
		.prompter{
			width: 100%;
			height: 100%;
		}
	}
}
.el-dropdown-link {
	cursor: pointer;
	color: #409EFF;
}
.el-icon-arrow-down {
	font-size: 12px;
}
</style>