
// config.js
export const other_params = [{
	title: "图片链接",
	type:1, //0选择框，1输入框
	option_value: "", 
	placeholder: "",
	prefix: "",
},{
	title: "其他描述",
	type:1, //0选择框，1输入框
	option_value: "", 
	placeholder: "",
	prefix: "",
}]
export const supple_descriptions = [
	{
		title: "Composition 构图", 
		option_value: "", 
		is_prefix: 0,
		option_num: 1, 
		option_list: [{
		  value: '',
		  label: 'None'
		}, {
		  value: 'symmetrical the composition',
		  label: 'symmetrical the composition 对称构图'
		}, {
		  value: 'Center the composition',
		  label: 'Center the composition 居中构图'
		}, {
		  value: 'rule of thirds composition',
		  label: 'rule of thirds composition 三分法构图'
		}, {
		  value: 'S-shaped composition',
		  label: 'S-shaped composition S型构图'
		}, {
		  value: 'Horizontal composition',
		  label: 'Horizontal composition 水平构图'
		}, {
		  value: 'diagonal composition',
		  label: 'diagonal composition 对角线构图'
		}, {
		  value: 'Asymmetrical Composition',
		  label: 'Asymmetrical Composition 不对称构图'
		}, {
		  value: 'Centered Composition',
		  label: 'Centered Composition 中心构图'
		}, {
		  value: 'Contrasting Composition',
		  label: 'Contrasting Composition 对比构图'
		}, {
		  value: 'Golden ratio',
		  label: 'Golden ratio 黄金分割'
		}, {
		  value: 'Proportional',
		  label: 'Proportional 比例适宜'
		}],
	},{
		title: "Camera视角", 
		option_value: "", 
		is_prefix: 0,
		option_num: 1, 
		option_list: [{
		  value: '',
		  label: 'None'
		},{
		  value: "A bird's-eye view",
		  label: "A bird's-eye view 鸟瞰图"
		}, {
		  value: 'Top view',
		  label: 'Top view 顶视图'
		}, {
		  value: 'tilt-shift',
		  label: 'tilt-shift 倾斜移位'
		}, {
		  value: 'satellite view',
		  label: 'satellite view 卫星视图'
		}, {
		  value: 'Bottom view',
		  label: 'Bottom view 底视图'
		}, {
		  value: 'extreme closeup view',
		  label: 'extreme closeup view 极端特写视图'
		}, {
		  value: 'up view',
		  label: 'up view仰视'
		}, {
		  value: 'first-person view',
		  label: 'first-person view 第一人称视角'
		}, {
		  value: 'isometric view',
		  label: 'isometric view 等距视图'
		}, {
		  value: 'closeup view',
		  label: 'closeup view 特写视图'
		}, {
		  value: 'high angle view',
		  label: 'high angle view 高角度视图'
		}, {
		  value: 'microscopic view',
		  label: 'microscopic view 微观'
		}, {
		  value: 'super side angle',
		  label: 'super side angle 超侧角'
		}, {
		  value: 'third-person perspective',
		  label: 'third-person perspective 第三人称视角'
		}, {
		  value: 'Chest Shot',
		  label: 'Chest Shot 陶部以上'
		}, {
		  value: 'Waist Shot',
		  label: 'Waist Shot 腰部以上'
		}, {
		  value: 'Knee Shot',
		  label: 'Knee Shot 膝盖以上'
		}, {
		  value: 'Full Length Shot',
		  label: 'Full Length Shot 全身'
		}, {
		  value: 'Long Shot',
		  label: 'Long Shot 人占3/4'
		}, {
		  value: 'Extra Long Shot',
		  label: 'Extra Long Shot 人在远方'
		}, {
		  value: 'Big Close-Up',
		  label: 'Big Close-Up 头部以上'
		}, {
		  value: 'Face Shot',
		  label: 'Face Shot 脸部特写'
		}, {
		  value: 'mandala',
		  label: 'mandala 曼茶罗构图'
		}, {
		  value: 'ultrawide shot',
		  label: 'ultrawide shot 超广角'
		}, {
		  value: 'extreme closeup',
		  label: 'extreme closeup 极端特写'
		}, {
		  value: 'macroshot',
		  label: 'macroshot 微距拍摄'
		}, {
		  value: 'profle',
		  label: 'profle 侧面'
		}, {
		  value: 'symmetricalbody',
		  label: 'symmetricalbody 对称的身体'
		}, {
		  value: 'Medium Long Shot',
		  label: 'Medium Long Shot 中远景'
		}, {
		  value: 'Long Shot Vision',
		  label: 'Long Shot 远景'
		}, {
		  value: 'over the shoulder shot',
		  label: 'over the shoulder shot 过肩景'
		}, {
		  value: 'loose shot',
		  label: 'loose shot 松散景'
		}, {
		  value: 'tight shot',
		  label: 'tight shot 近距离景'
		}, {
		  value: 'scenery shot',
		  label: 'scenery shot 风景照'
		}, {
		  value: 'bokeh',
		  label: 'bokeh 背景虚化'
		}, {
		  value: 'portrait',
		  label: 'portrait 肖像'
		}, {
		  value: 'Elevation perspective',
		  label: 'Elevation perspective 立面透视'
		}],
	},{
		title: "Lighting 灯光", 
		option_value: "", 
		is_prefix: 0,
		option_num: 1, 
		option_list: [{
		  value: '',
		  label: 'None'
		},{
		  value: 'beautiful lighting',
		  label: 'beautiful lighting 好看的灯光'
		}, {
		  value: 'soft light',
		  label: 'soft light 柔软的光线'
		}, {
		  value: 'Cinematic light',
		  label: 'Cinematic light 电影光'
		}, {
		  value: 'Volumetric light',
		  label: 'Volumetric light 体积光'
		}, {
		  value: 'Studio light',
		  label: 'Studio light 影棚光'
		}, {
		  value: 'Raking light',
		  label: 'Raking light 侧光'
		}, {
		  value: 'Edge light',
		  label: 'Edge light 边缘光'
		}, {
		  value: 'Back light',
		  label: 'Back light 逆光'
		}, {
		  value: 'Hard light',
		  label: 'Hard light 硬光'
		}, {
		  value: 'Bright',
		  label: 'Bright 明亮的光线'
		}, {
		  value: 'Top light',
		  label: 'Top light 顶光'
		}, {
		  value: 'Rim light',
		  label: 'Rim light 轮廓光'
		}, {
		  value: 'Morning light',
		  label: 'Morning light 晨光'
		}, {
		  value: 'Sun light',
		  label: 'Sun light 太阳光'
		}, {
		  value: 'Golden hour light',
		  label: 'Golden hour light 黄金时段光'
		}, {
		  value: 'Cold light',
		  label: 'Cold light 冷光'
		}, {
		  value: 'warm light',
		  label: 'warm light 暖光'
		}, {
		  value: 'Dramatic light',
		  label: 'Dramatic light 戏剧光'
		}, {
		  value: 'Color light',
		  label: 'Color light 色光'
		}, {
		  value: 'Cyberpunk light',
		  label: 'Cyberpunk light 赛博朋克光'
		}, {
		  value: 'volumetric lighting',
		  label: 'volumetric lighting 体积照明'
		}, {
		  value: 'mood lighting',
		  label: 'mood lighting 情绪照明'
		}, {
		  value: 'fluorescent lighting',
		  label: 'fluorescent lighting 荧光灯'
		}, {
		  value: 'Crepuscular Ray',
		  label: 'Crepuscular Ray 黄昏射线'
		}, {
		  value: 'outer space view',
		  label: 'outer space view 外太空观'
		}, {
		  value: 'Dramatic lighting',
		  label: 'Dramatic lighting 戏剧灯光'
		}, {
		  value: 'bisexual lighting',
		  label: 'bisexual lighting 双性照明'
		}, {
		  value: 'Rembrandt Lighting',
		  label: 'Rembrandt Lighting 伦勃朗照明'
		}, {
		  value: 'Split Lighting',
		  label: 'Split Lighting 分体照明'
		}, {
		  value: 'front lighting',
		  label: 'front lighting 前灯'
		}, {
		  value: 'clean background trending',
		  label: 'clean background trending 干净的背景趋势'
		}, {
		  value: 'global illuminations',
		  label: 'global illuminations 全局照明'
		}, {
		  value: 'neon cold lighting',
		  label: 'neon cold lighting 霓虹灯冷光'
		}],
	},{
		title: "Medium媒介", 
		option_value: "", 
		is_prefix: 1,
		option_list: [{
		  value: '',
		  label: 'None'
		},{
		  value: 'illustration of',
		  label: 'illustration插画'
		}, {
		  value: 'Vector of',
		  label: 'Vector向量图',
		}, {
		  value: 'oil painting of',
		  label: 'oil painting油画',
		}, {
		  value: 'photography of',
		  label: 'photography摄影',
		}, {
		  value: 'watercolor of',
		  label: 'watercolor水彩',
		}, {
		  value: 'sketch of',
		  label: 'sketch素描',
		}, {
		  value: 'sculpture of',
		  label: 'sculpture雕塑',
		}, {
		  value: 'ink painting of',
		  label: 'ink painting水墨画',
		}, {
		  value: 'Blockprint of',
		  label: 'Blockprint印刷版画',
		}, {
		  value: 'Lino cut of',
		  label: 'Lino cut利诺剪裁',
		}, {
		  value: 'manuscript of',
		  label: 'manuscript手稿',
		}],
	},{
		title: "Color色调", 
		option_value: "", 
		is_prefix: 0,
		option_num: 1,
		option_list: [{
		  value: '',
		  label: 'None'
		},{
		  value: 'White',
		  label: 'White白色'
		}, {
		  value: 'Black',
		  label: 'Black黑色',
		},{
		  value: 'blue',
		  label: 'blue蓝色',
		},{
		  value: 'red',
		  label: 'red红色',
		},{
		  value: 'green',
		  label: 'green绿色',
		},{
		  value: 'purple',
		  label: 'purple紫色',
		},{
		  value: 'pink',
		  label: 'pink粉红色',
		},{
		  value: 'brown',
		  label: 'brown棕色',
		},{
		  value: 'gray',
		  label: 'gray灰色',
		},{
		  value: 'mauve',
		  label: 'mauve紫红',
		},{
		  value: 'orange',
		  label: 'orange橙色',
		},{
		  value: 'yellow',
		  label: 'yellow黄色',
		},{
		  value: 'cyan',
		  label: 'cyan青色',
		},{
		  value: 'Gold',
		  label: 'Gold金色',
		},{
		  value: 'Silver',
		  label: 'Silver银色',
		},{
		  value: 'Beige',
		  label: 'Beige米色',
		},{
		  value: 'Cream',
		  label: 'Cream奶油色',
		},{
		  value: 'Sky blue',
		  label: 'Sky blue天蓝色',
		},{
		  value: 'Rustic tones',
		  label: 'Rustic tones乡村色调',
		},{
		  value: 'Bold and bright',
		  label: 'Bold and bright大胆明亮',
		},{
		  value: 'Retro',
		  label: 'Retro复古色调',
		},{
		  value: 'Pop art',
		  label: 'Pop art流行艺术色调',
		},{
		  value: 'Cinematic',
		  label: 'Cinematic电影色调',
		},{
		  value: 'Noir',
		  label: 'Noir黑白色调',
		},{
		  value: 'Fantasy',
		  label: 'Fantasy奇幻色调',
		},{
		  value: 'Gothic',
		  label: 'Gothic哥特式色调',
		},{
		  value: 'Gold and silver tone',
		  label: 'Gold and silver tone金银色调',
		},{
		  value: 'Dreamy',
		  label: 'Dreamy梦幻色调',
		},{
		  value: 'Romantic',
		  label: 'Romantic浪漫色调',
		},{
		  value: 'Dramatic',
		  label: 'Dramatic戏剧性色调',
		},{
		  value: 'High contrast',
		  label: 'High contrast高对比度色调',
		},{
		  value: 'Low contrast',
		  label: 'Low contrast低对比度色调',
		},{
		  value: 'Primary colors',
		  label: 'Primary colors原色',
		},{
		  value: 'Complimentary colors',
		  label: 'Complimentary colors互补色',
		},{
		  value: 'Triadic colors',
		  label: 'Triadic colors三色调',
		},{
		  value: 'Tetradic colors',
		  label: 'Tetradic colors四色调',
		},{
		  value: 'Warm white',
		  label: 'Warm white暖白色',
		},{
		  value: 'Cool white',
		  label: 'Cool white冷白色',
		},{
		  value: 'Black background centered',
		  label: 'Black background centered黑色背景为中心',
		},{
		  value: 'Colorful color matching',
		  label: 'Colorful color matching多色彩搭配',
		},{
		  value: 'Luminance',
		  label: 'Luminance亮度',
		},{
		  value: 'The low-purity tone',
		  label: 'The low-purity tone低纯度色调',
		},{
		  value: 'The high-purity tone',
		  label: 'The high-purity tone高纯度色调',
		},{
		  value: 'Contrast',
		  label: 'Contrast对比度',
		}],
	},{
		title: "Accuracy分辨率、精度、细节", 
		option_value: "", 
		is_prefix: 0,
		option_num: 1,
		option_list: [{
		  value: '',
		  label: 'None'
		},{
		  value: 'high detail',
		  label: 'high detail 高细节'
		}, {
		  value: 'hyper quality',
		  label: 'hyper quality高品质',
		}, {
		  value: 'high resolution',
		  label: 'high resolution高分辨率',
		}, {
		  value: '8k smooth',
		  label: '8k smooth 8k流畅',
		}, {
		  value: '8K Resolution',
		  label: '8K Resolution 8K分辨率',
		}, {
		  value: '16K Resolution',
		  label: '16K Resolution 分辨率16K',
		}, {
		  value: '4K resolution',
		  label: '4K resolution解析度',
		}, {
		  value: 'no background',
		  label: 'no background无背景',
		}, {
		  value: 'Ultra HD',
		  label: 'Ultra HD超高清',
		}, {
		  value: 'Super Clarity',
		  label: 'Super Clarity超清晰',
		}, {
		  value: 'High Defnition',
		  label: 'High Defnition高清',
		}, {
		  value: 'Ultrahigh Resolution',
		  label: 'Ultrahigh Resolution超高分辨率',
		}, {
		  value: 'Ultrahigh Clarity',
		  label: 'Ultrahigh Clarity超高清晰度',
		}, {
		  value: 'Ultra HD Picture',
		  label: 'Ultra HD Picture 超高清画面',
		}, {
		  value: 'Fine details',
		  label: 'Fine details精细细节',
		}, {
		  value: 'Realistic details',
		  label: 'Realistic details逼真的细节',
		}, {
		  value: 'Sharp details',
		  label: 'Sharp details锐利的细节',
		}, {
		  value: 'High-precision',
		  label: 'High-precision高精度',
		}, {
		  value: 'Ultra-realistic',
		  label: 'Ultra-realistic超逼真',
		}, {
		  value: 'Super sharp',
		  label: 'Super sharp超锐利',
		}, {
		  value: 'Crisp details',
		  label: 'Crisp details清晰的细节',
		}, {
		  value: 'Crystal clear',
		  label: 'Crystal clear水晶清晰',
		}, {
		  value: 'Picture-perfect',
		  label: 'Picture-perfect完美的画面',
		}, {
		  value: 'Photorealistic',
		  label: 'Photorealistic照片般逼真',
		}, {
		  value: 'Flawless',
		  label: 'Flawless无瑕疵的',
		}, {
		  value: 'Meticulously crafted',
		  label: 'Meticulously crafted精雕细琢',
		}],
	},{
		title: "Environment环境", 
		option_value: "", 
		is_prefix: 0,
		option_num: 1,
		option_list: [{
		  value: '',
		  label: 'None'
		},{
		  value: 'galaxy',
		  label: 'galaxy银河'
		}, {
		  value: 'dungeon',
		  label: 'dungeon黑暗地牢',
		},{
		  value: 'nebula',
		  label: 'nebula星云',
		},{
		  value: 'Hanging Gardens of Babylon',
		  label: 'Hanging Gardens of Babylon巴比伦空中花园',
		},{
		  value: 'meadow',
		  label: 'meadow草原草地',
		},{
		  value: 'overgrown nature',
		  label: 'overgrown nature杂草从生的',
		},{
		  value: 'post apocalyptic',
		  label: 'post apocalyptic后启示录、末日后',
		},{
		  value: 'Castle in the Sky',
		  label: 'Castle in the Sky天空之城',
		},{
		  value: 'Mythical World',
		  label: 'Mythical World神话世界',
		},{
		  value: 'Outer Space',
		  label: 'Outer Space外太空',
		},{
		  value: 'Magical Forest',
		  label: 'Magical Forest魔幻森林',
		},{
		  value: 'Ancient Temple',
		  label: 'Ancient Temple古代神庙',
		},{
		  value: 'Volcanic Eruption',
		  label: 'Volcanic Eruption火山喷发',
		},{
		  value: 'Futuristic Robots',
		  label: 'Futuristic Robots未来机器人',
		},{
		  value: 'Giant Machines',
		  label: 'Giant Machines巨大机器',
		},{
		  value: 'Mysterious Tomb',
		  label: 'Mysterious Tomb神秘古墓',
		},{
		  value: 'fantasy',
		  label: 'fantasy幻想',
		},{
		  value: 'classroom',
		  label: 'classroom教室',
		},{
		  value: 'whimsically',
		  label: 'whimsically异想天开',
		},{
		  value: 'bedroom',
		  label: 'bedroom卧室',
		},{
		  value: 'forest',
		  label: 'forest森林',
		},{
		  value: 'ruins',
		  label: 'ruins废墟',
		},{
		  value: 'city',
		  label: 'city城市',
		},{
		  value: 'deserted city buildings',
		  label: 'deserted city buildings废弃城市建筑群',
		},{
		  value: 'near future city',
		  label: 'near future city近未来都市',
		},{
		  value: 'street scenery',
		  label: 'street scenery街景',
		},{
		  value: 'Alchemy Laboratory',
		  label: 'Alchemy Laboratory炼金室',
		},{
		  value: 'universeIcosmos',
		  label: 'universeIcosmos宇宙',
		},{
		  value: 'rain',
		  label: 'rain雨天',
		},{
		  value: 'In the morning mist',
		  label: 'In the morning mist在晨雾中',
		},{
		  value: 'full of sunlight',
		  label: 'full of sunlight充满阳光',
		},{
		  value: 'Cyberpunk street',
		  label: 'Cyberpunk street赛博朋克街道',
		},{
		  value: 'Mystic temple',
		  label: 'Mystic temple神秘寺庙',
		},{
		  value: 'Ancient ruins',
		  label: 'Ancient ruins古代遗迹',
		},{
		  value: 'Desert oasis',
		  label: 'Desert oasis沙漠绿洲',
		},{
		  value: 'Lunar colony',
		  label: 'Lunar colony月球殖民地',
		},{
		  value: 'Steam-powered machinery',
		  label: 'Steam-powered machinery蒸汽动力机械',
		},{
		  value: 'Abandoned spaceship',
		  label: 'Abandoned spaceship废弃宇宙飞船',
		},{
		  value: 'Underworld',
		  label: 'Underworld冥界',
		},{
		  value: 'Neon city',
		  label: 'Neon city霓虹城市',
		},{
		  value: 'Alien planet',
		  label: 'Alien planet外星球',
		},{
		  value: 'Futuristic park',
		  label: 'Futuristic park未来公园',
		},{
		  value: 'Time travel cityscape',
		  label: 'Time travel cityscape时光穿越城市',
		},{
		  value: 'Robot factory',
		  label: 'Robot factory机器人工厂',
		},{
		  value: 'Cyber jungle',
		  label: 'Cyber jungle赛博朋克从林',
		},{
		  value: 'Mystic mountain',
		  label: 'Mystic mountain神秘山脉',
		},{
		  value: 'lce cave',
		  label: 'lce cave冰洞穴',
		},{
		  value: 'Underwater world',
		  label: 'Underwater world 水下世界',
		},{
		  value: 'Futuristic metropolis',
		  label: 'Futuristic metropolis未来都市',
		},{
		  value: 'Enchanted garden',
		  label: 'Enchanted garden魔法花园',
		},{
		  value: 'Magical castle',
		  label: 'Magical castle魔法城堡',
		},{
		  value: 'Crystal palace',
		  label: 'Crystal palace水晶宫殿',
		},{
		  value: 'Desolate Desert',
		  label: 'Desolate Desert荒漠孤烟',
		},{
		  value: 'Sunken Shipwreck',
		  label: 'Sunken Shipwreck沉船遗迹',
		},{
		  value: 'Cactus Desert',
		  label: 'Cactus Desert 仙人掌沙漠',
		},{
		  value: 'RainyCity',
		  label: 'RainyCity雨天城市',
		},{
		  value: 'Mushroom Forest',
		  label: 'Mushroom Forest蘑菇森林',
		},{
		  value: 'Fairy Tale Castle',
		  label: 'Fairy Tale Castle童话城堡',
		},{
		  value: 'Apocalypse Ruins',
		  label: 'Apocalypse Ruins未日废墟',
		},{
		  value: 'Star Wars',
		  label: 'Star Wars星球大战',
		},{
		  value: 'Mars Exploration',
		  label: 'Mars Exploration火星探险',
		},{
		  value: 'Enchanted Forest',
		  label: 'Enchanted Forest魔法森林',
		},{
		  value: 'Technological City',
		  label: 'Technological City 科技城市',
		},{
		  value: 'Romantic Town',
		  label: 'Romantic Town浪漫小镇',
		},{
		  value: 'Aurora Borealis',
		  label: 'Aurora Borealis极光北极',
		},{
		  value: 'Giant Architecture',
		  label: 'Giant Architecture巨大建筑',
		},{
		  value: 'Enchanted Garden',
		  label: 'Enchanted Garden迷人花园',
		},{
		  value: 'Post-Apocalyptic World',
		  label: 'Post-Apocalyptic World后启示录世界',
		},{
		  value: 'Magical Kingdom',
		  label: 'Magical Kingdom魔法王国',
		},{
		  value: 'Dystopian Landscape',
		  label: 'Dystopian Landscape反乌托邦景观',
		},{
		  value: 'Dark Forest',
		  label: 'Dark Forest 黑暗森林',
		},{
		  value: 'Lunar Landscape',
		  label: 'Lunar Landscape 月球景观',
		},{
		  value: 'Neon City',
		  label: 'Neon City霓I城市',
		},{
		  value: 'Steampunk Factory',
		  label: 'Steampunk Factory蒸汽朋克工厂',
		},{
		  value: 'Lost ruins',
		  label: 'Lost ruins迷失废墟',
		},{
		  value: 'lce kingdom',
		  label: 'lce kingdom冰雪王国',
		},{
		  value: 'Tropical paradise',
		  label: 'Tropical paradise热带天堂',
		},{
		  value: 'Post-apocalyptic wasteland',
		  label: 'Post-apocalyptic wasteland后启示录荒野',
		},{
		  value: 'Steampunk cityscape',
		  label: 'Steampunk cityscape蒸汽朋克城市',
		},{
		  value: 'Fantasy village',
		  label: 'Fantasy village奇幻村庄',
		},{
		  value: 'Magical forest',
		  label: 'Magical forest 魔法森林',
		},{
		  value: 'Futuristic transportation',
		  label: 'Futuristic transportation未来交通工具',
		},{
		  value: 'Gothic cathedral',
		  label: 'Gothic cathedral哥特式大教堂',
		},{
		  value: 'Floating city',
		  label: 'Floating city浮空城市',
		},{
		  value: 'Futuristic laboratory',
		  label: 'Futuristic laboratory未来实验室',
		},{
		  value: 'Space station',
		  label: 'Space station太空站',
		},{
		  value: 'Haunted mansion',
		  label: 'Haunted mansion幽灵庄园',
		},{
		  value: 'Digital cityscape',
		  label: 'Digital cityscape数字城市',
		},{
		  value: 'Pirate islan',
		  label: 'Pirate island海盗岛屿',
		}
		]
	},{
		title: "Style风格", 
		option_value: "", 
		is_prefix: 0,
		option_num: 1,
		option_list: [{
		  value: '',
		  label: 'None'
		},{
		  value: 'madmax',
		  label: 'madmax疯狂麦斯沙地风格'
		},{
		  value: 'Renaissance',
		  label: 'Renaissance文艺复兴',
		},{
		  value: 'Fauvism',
		  label: 'Fauvism野兽派',
		},{
		  value: 'Cubism',
		  label: 'Cubism立体派',
		},{
		  value: 'Abstract Art',
		  label: 'Abstract Art 抽象表现主义',
		},{
		  value: 'Surrealism',
		  label: 'Surrealism超现实主义',
		},{
		  value: 'OP Art /Optical Art',
		  label: 'OP Art /Optical Art欧普艺术/光效应艺术',
		},{
		  value: 'Victorian',
		  label: 'Victorian维多利亚时代',
		},{
		  value: 'futuristic',
		  label: 'futuristic未来主义',
		},{
		  value: 'Minimalist',
		  label: 'Minimalist极简主义',
		},{
		  value: 'brutalist',
		  label: 'brutalist粗犷主义',
		},{
		  value: 'Constructivist',
		  label: 'Constructivist建构主义',
		},{
		  value: 'botw',
		  label: 'botw旷野之息',
		},{
		  value: 'Warframe',
		  label: 'Warframe星际战甲',
		},{
		  value: 'Pokemon',
		  label: 'Pokemon宝可梦',
		},{
		  value: 'APEXApex',
		  label: 'APEXApex英雄',
		},{
		  value: 'From Software',
		  label: 'From Software魂系游戏',
		},{
		  value: 'partial anatomy',
		  label: 'partial anatomy局部解剖',
		},{
		  value: 'color ink on paper',
		  label: 'color ink on paper彩墨纸本',
		},{
		  value: 'doodle',
		  label: 'doodle涂鸦',
		},{
		  value: 'Voynich manuscript',
		  label: 'Voynich manuscript伏尼契手稿',
		},{
		  value: 'realistic',
		  label: 'realistic真实的',
		},{
		  value: '3D',
		  label: '3D 3D风格',
		},{
		  value: 'sophisticated',
		  label: 'sophisticated复杂的',
		},{
		  value: 'photoreal',
		  label: 'photoreal真实感',
		},{
		  value: 'national geographic',
		  label: 'national geographic国家地理',
		},{
		  value: 'hyperrealism',
		  label: 'hyperrealism超写实主义',
		},{
		  value: 'cinematic',
		  label: 'cinematic电影般的',
		},{
		  value: 'architectural sketching',
		  label: 'architectural sketching建筑素描',
		},{
		  value: 'symmetricalportrait',
		  label: 'symmetricalportrait对称肖像',
		},{
		  value: 'clear facial features',
		  label: 'clear facial features清晰的面部特征',
		},{
		  value: 'interior design',
		  label: 'interior design室内设计',
		},{
		  value: 'weapon design',
		  label: 'weapon design武器设计',
		},{
		  value: 'Game scene graph',
		  label: 'Game scene graph游戏场景图',
		},{
		  value: 'League of legends',
		  label: 'League of legends英雄联盟',
		},{
		  value: "jojo's bizarre adventure",
		  label: "jojo's bizarre adventure Jolo的奇妙冒险",
		},{
		  value: 'Makoto Shinkai',
		  label: 'Makoto Shinkai新海诚',
		},{
		  value: 'Soejima Shigenori',
		  label: 'Soejima Shigenori副岛成记',
		},{
		  value: 'Yamada Akihiro',
		  label: 'Yamada Akihiro山田章博',
		},{
		  value: 'Munashichi',
		  label: 'Munashichi六七质',
		},{
		  value: "Watercolor children's illustration",
		  label: "Watercolor children's illustration水彩儿童插画",
		},{
		  value: 'Ghibli Studio',
		  label: 'Ghibli Studio吉卜力风格',
		},{
		  value: 'Stained glass window',
		  label: 'Stained glass window彩色玻璃窗',
		},{
		  value: 'ink illustration',
		  label: 'ink illustration水墨插图',
		},{
		  value: 'Miyazaki Hayao style',
		  label: 'Miyazaki Hayao style宫崎骏风格',
		},{
		  value: 'Vincent Van Gogh',
		  label: 'Vincent Van Gogh梵高',
		},{
		  value: 'Leonardo Da Vinci',
		  label: 'Leonardo Da Vinci达芬奇',
		},{
		  value: 'manga',
		  label: 'manga漫画',
		},{
		  value: 'pointillism',
		  label: 'pointillism点彩派',
		},{
		  value: 'ink render',
		  label: 'ink render墨水渲染',
		},{
		  value: 'Ethnic Art',
		  label: 'Ethnic Art民族艺术',
		},{
		  value: 'retro dark vintage',
		  label: 'retro dark vintage复古黑暗复古',
		},{
		  value: 'Tradition Chinese Ink Painting style',
		  label: 'Tradition Chinese Ink Painting style国风',
		},{
		  value: 'Steampunk',
		  label: 'Steampunk蒸汽朋克',
		},{
		  value: 'fIm photography',
		  label: 'fIm photography电影摄影风格',
		},{
		  value: 'concept art',
		  label: 'concept art概念艺术',
		},{
		  value: 'montage',
		  label: 'montage剪辑',
		},{
		  value: 'full details',
		  label: 'full details充满细节',
		},{
		  value: 'Gothic gloomy',
		  label: 'Gothic gloomy哥特式黑暗',
		},{
		  value: 'realism',
		  label: 'realism写实主义',
		},{
		  value: 'black and white',
		  label: 'black and white黑白',
		},{
		  value: 'Unity Creations',
		  label: 'Unity Creations统一创作',
		},{
		  value: 'Baroque',
		  label: 'Baroque巴洛克时期',
		},{
		  value: 'lmpressionism',
		  label: 'lmpressionism印象派',
		},{
		  value: 'poster of japanese graphic design',
		  label: 'poster of japanese graphic design日本海报风格',
		}
		],
	},{
		title: "Rendering渲染", 
		option_value: "", 
		is_prefix: 0,
		option_num: 1,
		option_list: [{
		  value: '',
		  label: 'None'
		},{
		  value: 'Unreal Engine',
		  label: 'Unreal Engine虚幻引擎'
		},{
		  value: 'octane render',
		  label: 'octane render octane渲染',
		},{
		  value: 'Maxon Cinema 4D',
		  label: 'Maxon Cinema 4D Cinema4D渲染器',
		},{
		  value: 'Corona Render',
		  label: 'Corona Render室内渲染',
		},{
		  value: 'Quixel Megascans Render',
		  label: 'Quixel Megascans Render真实感',
		},{
		  value: 'VRay',
		  label: 'VRay V射线',
		},{
		  value: 'Blender',
		  label: 'Blender Blender渲染',
		},{
		  value: 'Lumion',
		  label: 'Lumion 灯光渲染',
		},{
		  value: 'architectural visualisation',
		  label: 'architectural visualisation建筑渲染',
		},{
		  value: 'surreal photography',
		  label: 'surreal photography超现实摄影',
		},{
		  value: 'realistic 3D',
		  label: 'realistic 3D逼真的3D',
		},{
		  value: 'zbrush',
		  label: 'zbrush zb',
		}
		]
	},{
		title: "National国风元素", 
		option_value: "", 
		is_prefix: 0,
		option_num: 1,
		option_list: [{
		  value: '',
		  label: 'None'
		},{
		  value: 'Hanfu',
		  label: 'Hanfu汉服'
		},{
		  value: 'Chinese costume',
		  label: 'Chinese costume中国风服装',
		},{
		  value: 'cheongsam',
		  label: 'cheongsam旗袍',
		},{
		  value: 'loong',
		  label: 'loong中国龙',
		},{
		  value: 'Chinese phoenix',
		  label: 'Chinese phoenix中国凤凰',
		},{
		  value: 'Chinese lanterns',
		  label: 'Chinese lanterns灯笼',
		},{
		  value: 'kylin',
		  label: 'kylin麒麟',
		},{
		  value: 'Kung Fu',
		  label: 'Kung Fu功夫',
		},{
		  value: 'Wing Tsun',
		  label: 'Wing Tsun咏春',
		},{
		  value: 'wuxia',
		  label: 'wuxia武侠',
		},{
		  value: 'Kunqu Opera',
		  label: 'Kunqu Opera昆曲',
		},{
		  value: 'Flute',
		  label: 'Flute笛子',
		},{
		  value: 'mahjong',
		  label: 'mahjong麻将',
		},{
		  value: 'Jade',
		  label: 'Jade玉',
		},{
		  value: 'Porcelain',
		  label: 'Porcelain瓷',
		},{
		  value: 'Cloisonne',
		  label: 'Cloisonne景泰蓝',
		},{
		  value: 'Enbroidered',
		  label: 'Enbroidered绣花的',
		},{
		  value: 'Gardens',
		  label: 'Gardens园林',
		},{
		  value: 'Pavilion',
		  label: 'Pavilion亭子',
		},{
		  value: 'xxTemplexx',
		  label: 'xxTemplexx寺庙',
		},{
		  value: 'Forbidden City',
		  label: 'Forbidden City紫禁城',
		},{
		  value: 'Summer Palace',
		  label: 'Summer Palace颐和园',
		},{
		  value: 'Peony',
		  label: 'Peony牡丹',
		},{
		  value: 'Plum',
		  label: 'Plum梅花',
		},{
		  value: 'Lotus',
		  label: 'Lotus莲花',
		}
		]
	},{
		title: "Photography style摄影风格", 
		option_value: "", 
		is_prefix: 0,
		option_num: 1,
		option_list: [{
		  value: '',
		  label: 'None'
		},{
		  value: 'Photo',
		  label: 'Photo写真风格'
		}, {
		  value: 'Documentary Photography',
		  label: 'Documentary Photography纪实风格',
		},{
		  value: 'Landscape Photography',
		  label: 'Landscape Photography风光摄影',
		},{
		  value: 'Humanitarian Photography',
		  label: 'Humanitarian Photography人文摄影',
		},{
		  value: 'Street Photography',
		  label: 'Street Photography街头摄影',
		},{
		  value: 'Fashion Photography',
		  label: 'Fashion Photography时尚摄影',
		},{
		  value: 'Advertising Photography',
		  label: 'Advertising Photography广告摄影',
		},{
		  value: 'Still Life Photography',
		  label: 'Still Life Photography静物摄影',
		},{
		  value: 'Portrait Photography',
		  label: 'Portrait Photography人像摄影',
		},{
		  value: 'Children Photography',
		  label: 'Children Photography儿童摄影',
		},{
		  value: 'Wedding Photography',
		  label: 'Wedding Photography婚纱摄影',
		},{
		  value: 'Photojournalism',
		  label: 'Photojournalism新闻摄影',
		},{
		  value: 'Sports Photography',
		  label: 'Sports Photography运动摄影',
		},{
		  value: 'Wildlife Photography',
		  label: 'Wildlife Photography动物摄影',
		},{
		  value: 'Black and White Photography',
		  label: 'Black and White Photography黑白摄影',
		},{
		  value: 'Color Photography',
		  label: 'Color Photography彩色摄影',
		},{
		  value: 'Collage Photography',
		  label: 'Collage Photography拼贴摄影',
		},{
		  value: 'Panoramic Photography',
		  label: 'Panoramic Photography全景摄影',
		},{
		  value: 'Night Photography',
		  label: 'Night Photography夜景摄影',
		},{
		  value: 'Macro Photography',
		  label: 'Macro Photography微距摄影',
		},{
		  value: 'Minimalist Photography',
		  label: 'Minimalist Photography极简主义风格',
		},{
		  value: 'Postmodern Photography',
		  label: 'Postmodern Photography后现代风格',
		},{
		  value: 'Experimental Photography',
		  label: 'Experimental Photography实验摄影',
		}
		]
	},{
		title: "Material quality材质", 
		option_value: "", 
		is_prefix: 0,
		option_num: 1,
		option_list: [{
		  value: '',
		  label: 'None'
		},{
		  value: 'Embossing',
		  label: 'Embossing浮雕(浅)'
		}, {
		  value: 'Etching',
		  label: 'Etching蚀刻(浅)',
		},{
		  value: 'Engraving',
		  label: 'Engraving雕(中度)',
		},{
		  value: 'Deep carving',
		  label: 'Deep carving刻(深)',
		},{
		  value: 'lvory',
		  label: 'lvory 象牙',
		},{
		  value: 'Obsidian',
		  label: 'Obsidian黑曜石',
		},{
		  value: 'Granite',
		  label: 'Granite花岗岩',
		},{
		  value: 'Basalt',
		  label: 'Basalt玄武岩',
		},{
		  value: 'Marble',
		  label: 'Marble大理石',
		},{
		  value: 'Pine',
		  label: 'Pine松木',
		},{
		  value: 'Pearl',
		  label: 'Pearl珍珠',
		},{
		  value: 'Jade',
		  label: 'Jade玉石',
		},{
		  value: 'Amber',
		  label: 'Amber琥珀',
		},{
		  value: 'Ruby',
		  label: 'Ruby红宝石',
		},{
		  value: 'Amethyst',
		  label: 'Amethyst紫水晶',
		},{
		  value: 'Diamond',
		  label: 'Diamond钻石',
		},{
		  value: 'Heliodor',
		  label: 'Heliodor太阳石',
		},{
		  value: 'Antique',
		  label: 'Antique做旧',
		},{
		  value: 'High Polished',
		  label: 'High Polished抛光',
		},{
		  value: 'Brushed',
		  label: 'Brushed拉丝',
		},{
		  value: 'Matte',
		  label: 'Matte哑光',
		},{
		  value: 'Satin',
		  label: 'Satin缎面',
		},{
		  value: 'Hammered',
		  label: 'Hammered捶打',
		},{
		  value: 'Sandblasted',
		  label: 'Sandblasted喷砂',
		},{
		  value: 'Ebony',
		  label: 'Ebony乌木',
		},{
		  value: 'Cuprite',
		  label: 'Cuprite赤铜',
		}
		]
	}
	];
 
 export const official_params = [
	 {
	 	title: "Iw图片权重", 
	 	type:0, //0选择框，1输入框
	 	option_value: "", 
	 	prefix: "--iw",
	 	remarks: "数值越高,图片权重越高(范围0.5-2)，以图生图才生效",
	 	option_list: [{
	 	  value: '',
	 	  label: 'None'
	 	},{
	 	  value: '0.5',
	 	  label: '0.5'
	 	}, {
	 	  value: '0.75',
	 	  label: '0.75'
	 	}, {
	 	  value: '1',
	 	  label: '1'
	 	}, {
	 	  value: '1.25',
	 	  label: '1.25'
	 	}, {
	 	  value: '1.5',
	 	  label: '1.5'
	 	}, {
	 	  value: '1.75',
	 	  label: '1.75'
	 	}, {
	 	  value: '2',
	 	  label: '2'
	 	}]
	 },{
	 	title: "Version版本", 
	 	type:0, //0选择框，1输入框
	 	option_value: "", 
	 	prefix: "",
	 	remarks: "使用不同版本的模型",
	 	option_list: [{
	 	  value: '',
	 	  label: 'None'
	 	},{
	 	  value: '--v 6.1',
	 	  label: '--v 6.1'
	 	}, {
	 	  value: '--v 6',
	 	  label: '--v 6'
	 	}, {
	 	  value: '--v 5.2',
	 	  label: '--v 5.2'
	 	},{
	 	  value: '--v 5.1',
	 	  label: '--v 5.1'
	 	},{
	 	  value: '--niji 5 --style cute',
	 	  label: '--niji 5 --style cute'
	 	},{
	 	  value: '--niji 5 --style scenic',
	 	  label: '--niji 5 --style scenic'
	 	},{
	 	  value: '--niji 5 --style expressive',
	 	  label: '--niji 5 --style expressive'
	 	},{
	 	  value: '--niji 5',
	 	  label: '--niji 5'
	 	},{
	 	  value: '--v 4 --style 4a',
	 	  label: '--v 4 --style 4a'
	 	},{
	 	  value: '--v4 --style 4b',
	 	  label: '--v4 --style 4b'
	 	},{
	 	  value: '--niji 4',
	 	  label: '--niji 4'
	 	},{
	 	  value: '--v 5',
	 	  label: '--v 5'
	 	},{
	 	  value: '--v 4',
	 	  label: '--v 4'
	 	},{
	 	  value: '--v 3',
	 	  label: '--v 3'
	 	},{
	 	  value: '--v 2',
	 	  label: '--v 2'
	 	},{
	 	  value: '--v 1',
	 	  label: '--v 1'
	 	},{
	 	  value: '--test',
	 	  label: '--test'
	 	},{
	 	  value: '--testp',
	 	  label: '--testp'
	 	},{
	 	  value: '--test --creative',
	 	  label: '--test --creative'
	 	},{
	 	  value: '--testp --creative',
	 	  label: '--testp --creative'
	 	}]
	 },{
 		title: "Aspect比例", 
 		type:0, //0选择框，1输入框
 		option_value: "", 
		prefix: "--ar",
		remarks: "更改纵横比，图片的比例",
 		option_list: [{
	 	  value: '',
	 	  label: 'None'
	 	},{
 		  value: '3:4',
 		  label: '3:4'
 		}, {
 		  value: '16:9',
 		  label: '16:9'
 		}, {
 		  value: '21:9',
 		  label: '21:9'
 		}, {
 		  value: '9:16',
 		  label: '9:16'
 		}]
 	},{
 		title: "Tile瓦片", 
 		type:0, //0选择框，1输入框
 		option_value: "", 
		prefix: "",
		remarks: "生成的图像可用作重复图块，为织物、壁纸和纹理创建无缝图案。",
 		option_list: [{
	 	  value: '',
	 	  label: 'None'
	 	},{
 		  value: '--tile',
 		  label: '--tile'
 		}]
 	},{
		title: "Stylize风格化", 
		type:1, //0选择框，1输入框
		option_value: "", 
		prefix: "--s",
		remarks: "0-1000,默认是100",
		placeholder: "数值越大，越艺术化，和描述关联性越低"
	},{
		title: "Quality质量", 
		type:1, //0选择框，1输入框
		option_value: "", 
		prefix: "--q",
		remarks: "默认值为 1 (0-2)",
		placeholder: "需要更多细节可以加大数值，但最大为2"
	},{
		title: "Chaos混乱", 
		type:1, //0选择框，1输入框
		option_value: "", 
		prefix: "--c",
		remarks: "0-100，改变结果的多样性",
		placeholder: "0为不变，值越大变化就越大"
	},{
		title: "Seed种子", 
		type:1, //0选择框，1输入框
		option_value: "", 
		prefix: "--seed",
		remarks: "范围（0–4294967295）",
		placeholder: "图片的种子，控制种子可以控制图片的相似性"
	},{
		title: "No排除", 
		type:1, //0选择框，1输入框
		option_value: "", 
		prefix: "--no",
		remarks: "负面提示词(多条数据用空格隔开)",
		placeholder: "你不想出现在图里的元素"
	}
	];